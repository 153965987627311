const ProfilePicture = (
    {
        color,
        description,
        descriptionClass,
        imageSrc,
        margin,
        name,
        profileClick,
        size,
        showName,
        nameClass,
    }) => {

    const onClick = (event) => {
        if (profileClick !== null) {
            profileClick(event);
        }
    }

    const innerHtmlFn = () => {
        return (
            <div>
                <img className={!!color ? 'profilePicLink' : ''} title={name} alt={name} src={imageSrc}
                    style={{
                        borderColor: color,
                        objectFit: 'cover',
                        height: size,
                        width: size,
                        borderRadius: '50%',
                        margin: margin,
                        verticalAlign: 'middle',
                    }} >
                </img>
                {!showName ? <></> :
                    <div className={nameClass}>
                        {name}
                    </div>
                }
                {!description ? <></> :
                    <div className={descriptionClass}>
                        {description}
                    </div>
                }
            </div>
        )
    }

    const innerHtml = innerHtmlFn();

    return (profileClick ?
        <button onClick={onClick}>
            {innerHtml}
        </button> :
        <div style={{ display: 'inline-block'}}>
            {innerHtml}
        </div>
    );
};

ProfilePicture.defaultProps = {
    showName: false,
    nameClass: '',
    descriptionClass: 'profilePictureDescription',
}

export default ProfilePicture;
