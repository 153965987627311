import { shuffle } from 'lodash';
import { useEffect, useState } from 'react';
import TopicButton from './TopicButton';
import { Refresh } from '@material-ui/icons';

const ExampleTopics = ({ open, selectedSearchTopic }) => {
    const trendingTopics = [
        'love',
        'humor',
        'passion',
        'kobe bryant',
        "food",
        "movies",
        "google",
        "facebook",
        "tv shows",
        "empathy",
        "alcohol",
        "climate change",
        "marriage",
        "photography",
        "music",
        "misinformation",
        "universal basic income",
        "artificial intelligence",
        "comedy",
        "marijuana",
        "anxiety",
        "design",
        "china",
        "openai",
        "healthcare",
        "happiness",
        "tennis",
        "education",
        "tiktok",
        "basketball",
        "kids",
        "abortion",
        "russia",
        "startups",
        "economy",
        "restaurants",
        "metaverse",
        "journalism",
        "travel",
        "venture capital",
        "architecture",
        "fashion",
        "cars",
        "machine learning",
        "cooking",
        "parenting",
        "relationships",
        "dating",
        "crypto",
        "investing",
        "nba",
        "exercise",
        "books",
        "creativity",
        "writing",
        "singing",
        "acting",
        "depression",
        "friendship",
        "israel",
        "running",
        "climbing",
        "death"
    ];

    const [exampleTopics, setExampleTopics] = useState([]);

    useEffect(() => {
        if (open) {
            setExampleTopics(shuffle(trendingTopics).slice(0, 5));
        }
    }, [open])

    const newSuggestionsClick = () => {
        const existingTopics = new Set();
        for (let i = 0; i < exampleTopics.length; i++) {
            existingTopics.add(exampleTopics[i]);
        }
        setExampleTopics(
            shuffle(trendingTopics.filter(x => !existingTopics.has(x))).slice(0, 5)
        );
    }

    return (
        <div className='animate__animated animate__fadeIn'>
            <div>
                <div>
                    {exampleTopics.map((topic, i) => (
                        <TopicButton key={i} query={''} name={topic} selectedSearchTopic={selectedSearchTopic} />
                    ))}
                </div>
                <div style={{ textAlign: 'right', paddingTop: '20px', paddingRight: '40px'}}>
                    <button onClick={newSuggestionsClick}>
                        <Refresh style={{ verticalAlign: 'middle' , color: 'rgb(124, 124, 124)', fontSize: '20px' }} />
                        <span style={{ verticalAlign: 'middle', paddingLeft: '4px' }}>
                            Refresh Examples
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ExampleTopics;
