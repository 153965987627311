import axios from 'axios';

import { useEffect, useState } from 'react'
import PropTypes from 'prop-types';

// Redux.
import { connect } from 'react-redux';
import {
    forMeLoadingCountDecrease,
    forMeLoadingCountIncrease,
    setGetStartedTopicsStatusTrue,
    updateForMe
} from '../redux/actions/userActions';

// Router.
import { Navigate, useNavigate } from 'react-router-dom';

// Firebase.
import { getAnalytics, logEvent } from "firebase/analytics";

// Components.

import { Close } from '@material-ui/icons';
import GetStartedEmojis from '../components/GetStartedEmojis';
import PodcastFlipper from '../components/PodcastFlipper';
import { validTokenExists } from '../firebaseHelper';


const GetStarted = ({
    authenticated,
    forMeLoadingCountDecrease,
    forMeLoadingCountIncrease,
    setGetStartedTopicsStatusTrue,
    updateForMe,
}) => {
    const [picksSelected, setPicksSelected] = useState(false);

    useEffect(() => {
        const analytics = getAnalytics();
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'get_started'
        });
    }, []);

    const navigate = useNavigate();

    const exit = () => {
        navigate('/home');
    }

    const topicSelections = (selectedTopics, unselectedTopics) => {
        // TODO handle error cases and don't auto dismiss podcast flipper if request hasn't finished yet.
        setGetStartedTopicsStatusTrue();
        validTokenExists()
            .then(() => {
                forMeLoadingCountIncrease();
                axios
                    .post('/set_user_topics', {
                        selectedTopics: selectedTopics,
                        unselectedTopics: unselectedTopics,
                    })
                    .then((res) => {
                        forMeLoadingCountDecrease();
                        if (res.data.error) {
                            // TODO display error message to user...
                            console.log(res.data.error);
                        } else {
                            updateForMe(res.data);
                            onFinished();
                        }
                    })
                    .catch((err) => {
                        forMeLoadingCountDecrease();
                        // TODO display error message to user...
                        console.log(err.response.data.error);
                    });
            })
            .catch((e) => {
                forMeLoadingCountDecrease();
                // Not logged in.
            });
        setPicksSelected(true);
    }

    const onFinished = () => {
        navigate('/home');
    }

    if (!authenticated) {
        return <Navigate to={'/login'}></Navigate>
    } else {
        return (
            <div className="container bottomPadding">
                <div className='mainWithBottomMenu'>
                    {picksSelected ?
                        <div style={{ paddingTop: '60px' }}>
                            <PodcastFlipper loadingText='Curating podcasts for you' onFinished={onFinished} />
                        </div>
                        :
                        <div style={{ padding: '0px 0px' }}>
                            <div className='heading' style={{ padding: '20px' }}>
                                Show me podcasts on...
                                <button onClick={exit} style={{ float: 'right', height: '40px', verticalAlign: 'middle' }}>
                                    <Close style={{ color: 'rgb(124, 124, 124' }} />
                                </button>
                            </div>

                            <GetStartedEmojis topicSelections={topicSelections} />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

GetStarted.propTypes = {
    authenticated: PropTypes.bool.isRequired
}

GetStarted.defaultProps = {

}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    getStartedTopicStatus: state.user.getStartedTopicStatus,
})

const mapActionsToProps = {
    forMeLoadingCountDecrease,
    forMeLoadingCountIncrease,
    updateForMe,
    setGetStartedTopicsStatusTrue,
}

export default connect(mapStateToProps, mapActionsToProps)(GetStarted);