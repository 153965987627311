import axios from 'axios';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useEffect, useState } from 'react'

import { getAnalytics } from "firebase/analytics";

import Loading from './Loading';
import { loadTrack } from './audioHelper'

import { validTokenExists } from '../firebaseHelper';
import { addSavedTrackIds } from '../redux/actions/userActions';

import { Refresh } from '@material-ui/icons';
import TrackInfoRow from './TrackInfoRow';


const SavedTracks = (props) => {
    const { addSavedTrackIds, savedTrackIds, playTrack } = props;
    const analytics = getAnalytics();

    const [trackInfos, setTrackInfos] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(null);

    const refresh = () => {
        setTrackInfos(null);
        validTokenExists()
            .then(() => {
                axios
                    .post('/saved_tracks', {})
                    .then((res) => {
                        const fetchedTrackInfos = res.data.trackInfos;
                        setTrackInfos(fetchedTrackInfos);
                        setLastUpdated(res.data.lastUpdated);
                        const fetchedTrackIds = [];
                        for (let i = 0; i < fetchedTrackInfos.length; i++) {
                            fetchedTrackIds.push(fetchedTrackInfos[i].id);
                        }
                        addSavedTrackIds(fetchedTrackIds);
                    })
                    .catch((err) => {
                        // TODO
                        console.log(err);
                        setTrackInfos([]);
                        setLastUpdated(null);
                    });
            })
            .catch((e) => {
                // TODO
                console.log(e);
                setTrackInfos([]);
                setLastUpdated(null);
            });
    }

    useEffect(() => {
        refresh();
    }, []);

    const isTrackSaved = (trackId) => {
        const savedTrackIdSet = new Set(savedTrackIds);
        return savedTrackIdSet.has(trackId);
    };

    return (
        <div>
            {trackInfos === null ? <Loading /> :
                <div>
                    <div>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '100%' }}>
                                        <div className='heading' style={{ paddingLeft: '20px' }}>
                                            Saved
                                        </div>
                                    </td>
                                    <td style={{ minWidth: '150px', textAlign: 'right' }}>
                                        <button style={{ padding: '20px' }} onClick={refresh}>
                                            <Refresh style={{ fontSize: '24px' }} />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {trackInfos.length !== 0 ? <></> :
                        <div style={{ padding: '20px', fontSize: '16px' }}>
                            Saved episodes will show up here.
                        </div>
                    }

                    {trackInfos.map((trackInfo, index) => (
                        <div key={index} style={{ opacity: isTrackSaved(trackInfo.id) ? '1' : '0.3' }}>
                            <TrackInfoRow
                                trackInfo={trackInfo}
                                playTrack={playTrack}
                            />
                        </div>
                    ))}

                    {trackInfos.length !== 10 ? <></> :
                        <div style={{ padding: '20px', color: 'rgb(80, 80, 80)', fontSize: '15px' }}>
                            10 most recently saved episodes are shown.
                            Unsave and refresh to see older saved episodes.
                        </div>
                    }
                </div>
            }
        </div>
    );
}

SavedTracks.propTypes = {

}

SavedTracks.defaultProps = {

}

const mapStateToProps = (state) => ({
    savedTrackIds: state.user.savedTrackIds,
})

const mapActionsToProps = {
    addSavedTrackIds,
}

export default connect(mapStateToProps, mapActionsToProps)(SavedTracks);
