import { IosShareRounded } from "@mui/icons-material";
import SpeedActions from "./SpeedActions";
import ClipShareButton from "./ClipShareButton";

const ClipActions = ({
    clip,
    onSpeedClick,

}) => {

    return <div>
        <SpeedActions onSpeedClick={onSpeedClick} />

        <div style={{ textAlign: 'center', padding: '10px' }}>
            <ClipShareButton
                buttonStyle={{ verticalAlign: 'middle' }}
                iconStyle={{ fontSize: '24px', verticalAlign: 'middle' }}
                trackId={clip.fullTrackId}
                messageIndex={clip.messageIndex}
                title={clip.title}
                showLabel={true}
            />
        </div>

    </div>;
};

export default ClipActions;