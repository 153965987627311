import update from 'immutability-helper';

import {
    LOAD_SUGGESTED_TOPICS,
    SET_NEXT_CLIPS_INFO,
    SET_RELATED_TOPIC_CLIPS,
    SET_SEARCH_TEXT,
    SET_SUGGESTED_TOPICS,
    SET_TRACK,
    SET_UNAUTHENTICATED,
    SHUFFLE_SUGGESTED_TOPICS,
} from '../types';
import { shuffle } from 'lodash';

const initialState = {
    nextClipsInfo: null,
    relatedTopicClips: [],
    searchText: '',
    suggestedTopics: [],
    // null means loading.
    suggestedTopicsUpdated: 0,
    track: null,

};

function shuffleSuggestedTopics(suggestedTopics) {
    if (!suggestedTopics) {
        return suggestedTopics;
    }
    const shuffledTopics = shuffle(suggestedTopics);
    for (let i = 0; i < shuffledTopics.length; i++) {
        shuffledTopics[i].clips = shuffle(shuffledTopics[i].clips);
    }
    return shuffledTopics;
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_SUGGESTED_TOPICS:
            return {
                ...state,
                suggestedTopics: [],
                suggestedTopicsUpdated: null,
            };
        case SET_NEXT_CLIPS_INFO:
            return {
                ...state,
                nextClipsInfo: action.payload,
                relatedTopicClips: [],
            };
        case SET_RELATED_TOPIC_CLIPS:
            return {
                ...state,
                relatedTopicClips: action.payload,
            };
        case SET_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.payload,
            };
        case SET_SUGGESTED_TOPICS:
            return {
                ...state,
                suggestedTopics: action.payload,
                suggestedTopicsUpdated: Date.now(),
            };
        case SET_TRACK:
            const track = action.payload;
            return {
                ...state,
                // track: action.payload,
                track: track,
            };
        case SET_UNAUTHENTICATED:
            return {
                ...initialState,
            };
        case SHUFFLE_SUGGESTED_TOPICS:
            return {
                ...state,
                suggestedTopics: shuffleSuggestedTopics(state.suggestedTopics)
            }
        default:
            return state;
    }
};