import { IosShareRounded, Search } from "@mui/icons-material";
import BookmarkButton from "./BookmarkButton";
import SpeedActions from "./SpeedActions";

const TrackActions = ({
    authenticated,
    onShareClick,
    onSpeedClick,
    setOpenSearchTranscript,
    showLabels,
    track,
}) => {
    const shareIconStyle = {
        fontSize: '21px',
        marginBottom: '-18px',
    }

    const bookmarkIconStyle = {
        fontSize: '22px',
        marginBottom: '-20px',
    }

    const searchIconStyle = {
        fontSize: '22px',
        marginBottom: '-20px',
    }

    return !track ? <></>
        : <div>
            <SpeedActions onSpeedClick={onSpeedClick}/>

            <table style={{ borderSpacing: 0, width: '100%', textAlign: 'center' }}>
                <tbody>
                    <tr>
                        <td style={{ width: '33%' }}>
                            <button onClick={() => { setOpenSearchTranscript(true); }} className='trackOverviewActionButton'>
                                <div className='trackOverviewActionIcon'>
                                    <Search style={searchIconStyle} />
                                </div>
                                <span style={{ display: showLabels ? 'inline' : 'none' }}>
                                    Search
                                </span>
                            </button>
                        </td>

                        <td style={{ width: '34%' }}>
                            <button onClick={onShareClick} className='trackOverviewActionButton'>
                                <div className='trackOverviewActionIcon'>
                                    <IosShareRounded style={shareIconStyle} />
                                </div>
                                <span style={{ display: showLabels ? 'inline' : 'none' }}>
                                    Share
                                </span>
                            </button>
                        </td>
                        {!authenticated ? <></> :
                            <td style={{ width: '33%' }}>
                                <BookmarkButton
                                    trackId={track.fullTrackId ?? track.id}
                                    buttonClassName='trackOverviewActionButton'
                                    iconClassName={showLabels ? 'trackOverviewActionIcon' : ''}
                                    iconStyle={bookmarkIconStyle} />
                            </td>
                        }
                    </tr>
                </tbody>
            </table>
        </div>;
};

export default TrackActions;

