import axios from 'axios';

import { useEffect, useRef, useState } from 'react'

// Redux.
import { connect } from 'react-redux';

import { validTokenExists } from '../firebaseHelper';
import PodcastTrack from './PodcastTrack';


const PodcastLatestEpisodes = (props) => {
    const {
        podcastId,

        // Redux
        authenticated,
    } = props;

    const [trackInfos, setTrackInfos] = useState([]);

    useEffect(() => {
        validTokenExists()
            .then(() => {
                axios
                    .post('/podcast_tracks', {
                        podcastId: podcastId
                    })
                    .then((res) => {
                        setTrackInfos(res.data.trackInfos);
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            })
            .catch((e) => {
                console.log(e);
            });
    }, [podcastId]);

    return (
        <div>
            {
                trackInfos.length === 0 ?
                    <></>
                    :
                    <div>
                        <div className='entityInfoSectionTitle'>
                            Trending Episodes
                        </div>
                        {
                            trackInfos.map((trackInfo, index) => (
                                <div key={index} style={{ marginLeft: '20px', padding: '10px 0px', borderTop: index === 0 ? '' : '1px solid rgb(224, 224, 224)' }}>
                                    <PodcastTrack trackInfo={trackInfo} authenticated={authenticated} />
                                </div>
                            ))
                        }
                    </div>
            }
        </div >
    );
}

PodcastLatestEpisodes.propTypes = {

}

PodcastLatestEpisodes.defaultProps = {

}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(PodcastLatestEpisodes);
