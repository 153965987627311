
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react'

import {
    setNextClipsInfo,
} from "../redux/actions/dataActions";

import { shuffle } from 'lodash';

import { loadTrack } from './audioHelper';
import { eventLog } from './eventHelper';

import { LABEL_APPNAME } from '../constants/labels';

// Algolia.
import { ALGOLIA_API_KEY, ALGOLIA_APP_ID } from '../constants/algolia_constants';
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite.css';
import {
    useHits,
    useSearchBox,
} from 'react-instantsearch-core';

import {
    Configure,
    Highlight,
    Index,
    InstantSearch
} from 'react-instantsearch';


// Components.
import EntityInfoDrawer from './EntityInfoDrawer';
import SpeakerHeader from './SpeakerHeader';
import SpeakerTrack from './SpeakerTrack';
import TopicSpeakerClips from './TopicSpeakerClips';

import { ChevronRightRounded, PlayCircleFilled, SearchRounded, Timer } from '@material-ui/icons';

import Highlighter from 'react-highlight-words';
import useWindowDimensions from './windowHelper';
import BottomHomeMenu from './BottomHomeMenu';
import { fancyTimeFormatWithHMS, getDateString } from './timeHelper';
import BookmarkButton from './BookmarkButton';


const ClipsResultsLabel = ({ query, hits }) => {
    return (
        <div style={{ padding: '20px 20px 10px' }}>
            {
                hits.length === 0 ?
                    <div className='heading'>
                        No previews found for "{query}"
                    </div>
                    :
                    <div className='subheading'>
                        Episodes and Sections
                    </div>
            }
        </div>
    )
}

const AlgoliaSearch = ({
    initialQuery,
    noQueryView,

    // Redux.
    authenticated,
    setNextClipsInfo,
}) => {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    const navigateToUrl = (url) => () => {
        navigate(url);
    }

    useEffect(() => {
        if (!!initialQuery) {
            eventLog(4, {
                'topicId': initialQuery,
            });
        }
    }, []);

    const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

    const relatedTopicClipsClick = (relatedTopicClips) => () => {
        loadTrack(relatedTopicClips.clips[0].mp3URL, 0);
        setNextClipsInfo(relatedTopicClips.clips);
        navigate('/clips', { state: { closeAllModal: true } });
    };

    const playSearchResult = (query, hit, hits) => {
        let clips = shuffle(hits.filter(clip => clip.id !== hit.id));
        clips.unshift(hit);
        loadTrack(hit.mp3URL, 0);
        relatedTopicClipsClick({
            'id': LABEL_APPNAME,
            'clips': clips,
        })();
        eventLog(5, {
            'clipId': hit.id,
            'speakerId': hit.person.id,
            'topicIds': hit.topics,
            'query': query,
        })
        navigate('/clips');
    };

    const playTrack = (trackId) => () => {
        navigate('/track/' + trackId);
    }

    const playSuggestion = (suggestion, clipId) => () => {
        suggestion.clips = shuffle(suggestion.clips);

        if (clipId) {
            const newClips = [];
            for (let i = 0; i < suggestion.clips.length; i++) {
                const clip = suggestion.clips[i];
                if (clip.id === clipId) {
                    newClips.push(clip);
                    for (let j = 0; j < suggestion.clips.length; j++) {
                        if (j !== i) {
                            newClips.push(suggestion.clips[j])
                        }
                    }
                    break;
                }
            }
            suggestion.clips = newClips;
        }

        loadTrack(suggestion.clips[0].mp3URL, 0)
        relatedTopicClipsClick(suggestion)();
        navigate('/clips');
    }

    const SearchTopicSuggestions = (props) => {
        const { query, refine, clear } = useSearchBox(props);
        return (
            <Index indexName="topics">
                <Configure hitsPerPage={3} />
                <TopicSuggestionHits query={query} refine={refine} playSuggestion={props.playSuggestion} width={props.width} />
            </Index>
        );
    }

    const MaybeShowPerson = ({ query, hits, authenticated }) => {

        const [person, setPerson] = useState(null);
        const [speakerTracks, setSpeakerTracks] = useState([]);

        const [openPersonInfo, setOpenPersonInfo] = useState(false);

        const onSpeakerClick = () => {
            setOpenPersonInfo(true);
        };

        const togglePersonInfo = (open) => (event) => {
            if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            if (!!person && !!person.id) {
                setOpenPersonInfo(open);
            }
        }

        useEffect(() => {
            setPerson(null);
            if (hits && query) {
                for (let i = 0; i < hits.length; i++) {
                    const hitPerson = hits[i].speaker;
                    if (query.length * 4 >= hitPerson.name.length) {
                        if (hitPerson.name.toLowerCase().indexOf(query.toLowerCase()) != -1) {
                            setPerson(hitPerson);
                            setSpeakerTracks(hits[i].tracks);
                        }
                        break;
                    }
                }
            }
        }, [query, hits]);

        return (
            !person ? <div className='thinRightDivider'></div> :
                <div style={{ padding: '16px 0px' }}>
                    <EntityInfoDrawer
                        open={openPersonInfo}
                        onClose={togglePersonInfo(false)}
                        speakerId={person.id} />
                    <SpeakerHeader summary={null} speaker={person} onSpeakerClick={onSpeakerClick} />

                    <div>
                        <div className='subheading' style={{ padding: '20px 20px 0px' }}>
                            Trending episodes with {person.name}
                        </div>
                        {
                            speakerTracks.map((track, index) => (
                                <div key={index} style={{ marginLeft: '20px', padding: '10px 0px', borderTop: index === 0 ? '' : '1px solid rgb(224, 224, 224)' }}>
                                    <SpeakerTrack speakerId={person.id} track={track} authenticated={authenticated} />
                                </div>
                            ))
                        }
                    </div>

                </div>
        )
    };

    const ClipHits = (props) => {
        const { query, refine, clear } = useSearchBox(props);
        const { hits, results, sendEvent } = useHits(props);
        const {
            playSearchResult,
        } = props;

        useEffect(() => {
            document.getElementById('homeContent').scrollTo(0, 0)
            document.getElementById('audio').pause();
        }, [query]);

        const groupHitsByTrack = () => {
            let trackIdOrder = [];
            let trackIdToHits = {};
            for (let i = 0; i < hits.length; i++) {
                const hit = hits[i];
                const trackId = hit.fullTrackId;
                if (!(trackId in trackIdToHits)) {
                    trackIdOrder.push(trackId);
                    trackIdToHits[trackId] = [hit];
                } else {
                    trackIdToHits[trackId].push(hit);
                }
            }
            let retval = [];
            for (let i = 0; i < trackIdOrder.length; i++) {
                const trackId = trackIdOrder[i];
                retval.push({
                    'id': trackId,
                    'hits': trackIdToHits[trackId]
                })
            }
            return retval;
        }

        const Hit = ({ hit }) => {
            return (
                <div>
                    <table style={{ textAlign: 'left' }}>
                        <tbody>
                            <tr>
                                <td style={{ minWidth: '66px', verticalAlign: 'top' }}>
                                    <button onClick={() => {
                                        playSearchResult(query, hit, hits)
                                    }}>
                                        <img alt='profile' src={hit.person.imageURL} style={{ width: '55px', height: '55px', border: '2px solid rgb(224, 224, 224)', padding: '2px', borderRadius: '50%' }}></img>
                                    </button>
                                </td>
                                <td style={{ width: '100%', verticalAlign: 'top' }}>
                                    <div>
                                        <button className='clipSearchResultTitle'
                                            onClick={() => {
                                                playSearchResult(query, hit, hits)
                                            }}>
                                            <Highlight hit={hit} attribute="title" />
                                        </button>
                                    </div>
                                    <div>
                                        <button onClick={() => {
                                            playSearchResult(query, hit, hits)
                                        }} style={{ margin: '8px 0px', textAlign: 'left' }}>
                                            <div className='speakerDescription' style={{ fontWeight: '500' }}>
                                                <Highlight hit={hit} attribute="person.name" />
                                            </div>
                                            <div className='speakerDescription'>
                                                <Highlight hit={hit} attribute="person.description" />
                                            </div>
                                        </button>
                                    </div>
                                    <div className='speakerDescription'>
                                        <Timer style={{ verticalAlign: 'middle', fontSize: '16px', marginRight: '5px' }} />
                                        <span style={{ verticalAlign: 'middle' }}>
                                            {fancyTimeFormatWithHMS(hit.totalTimeSeconds)}
                                        </span>

                                    </div>
                                </td>
                                <td>
                                    <ChevronRightRounded style={{ fontSize: '34px', color: 'rgb(120, 120, 120', verticalAlign: 'middle' }} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }

        return (
            <div className='searchResults'>
                <ClipsResultsLabel query={query} hits={hits} />
                {/* <Hits hitComponent={Hit} /> */}

                <div style={{ padding: '16px 0px' }}>
                    {groupHitsByTrack().map((hitsByTrack, trackIndex) => (
                        <div key={trackIndex} style={{ paddingTop: trackIndex === 0 ? '0px' : '20px' }}>
                            <table style={{ width: '100%', textAlign: 'left', padding: '0px 20px' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ minWidth: '76px' }}>
                                            <button onClick={navigateToUrl('/podcast/' + hitsByTrack.hits[0].source.id)}>
                                                <img src={hitsByTrack.hits[0].source.imageURL} className='trackInfoSourceImage' />
                                            </button>
                                        </td>
                                        <td style={{ width: '100%' }}>
                                            <button
                                                onClick={playTrack(hitsByTrack.hits[0].fullTrackId)}
                                                style={{ textAlign: 'left', width: '100%' }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    <div className='trackInfoSourceTitle'>
                                                        {hitsByTrack.hits[0].source.title}
                                                    </div>
                                                </div>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ margin: '16px 20px' }}>
                                {hitsByTrack.hits.map((hit, hitIndex) => (
                                    <div key={hit.id} style={{ borderLeft: '1px solid rgb(224, 224, 224)', paddingLeft: '10px', paddingTop: hitIndex === 0 ? '0px' : '16px' }}>
                                        <Hit hit={hit} />
                                    </div>
                                ))}
                            </div>

                            <table style={{ cellSpacing: 0, padding: '0px 20px 20px' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            <button onClick={playTrack(hitsByTrack.hits[0].fullTrackId)}>
                                                <PlayCircleFilled style={{
                                                    verticalAlign: 'middle',
                                                    fontSize: '30px',
                                                    marginTop: '0px',
                                                }} />
                                                <span style={{ color: 'rgb(80, 80, 80)', fontSize: '14px', verticalAlign: 'middle', paddingLeft: '8px' }}>
                                                    {getDateString(hitsByTrack.hits[0].publishedDate)}
                                                </span>
                                            </button>
                                        </td>
                                        <td>
                                            <BookmarkButton trackId={hitsByTrack.id}
                                                buttonClassName=''
                                                iconStyle={{
                                                    verticalAlign: 'middle',
                                                    fontSize: '25px',
                                                }} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ))}
                </div>
            </div >
        )
    }

    const SearchTopics = (props) => {
        const { query, refine, clear } = useSearchBox(props);
        return (
            <Index indexName="topics">
                <Configure hitsPerPage={1} />
                <TopicHits query={query} refine={refine} playSuggestion={props.playSuggestion} width={props.width} />
            </Index>
        );
    }

    const TopicHits = (props) => {
        const { hits, results, sendEvent } = useHits(props);

        const isExactMatch = (hit) => {
            return hit.name === props.query.toLowerCase();
        }

        const hasMultipleSpeakers = (hit) => {
            let speakersIds = new Set();
            for (let i = 0; i < hit.clips.length; i++) {
                const clip = hit.clips[i];
                speakersIds.add(clip.person.id);
            }
            return speakersIds.size > 1;
        }

        const TopicHit = ({ hit }) => {
            return (
                isExactMatch(hit) && hasMultipleSpeakers(hit) ?
                    <div style={{ padding: '10px 16px 0px 0px', marginLeft: '20px' }}>
                        <div style={{ padding: '0px 4px' }}>
                            <TopicSpeakerClips
                                playTopic={props.playSuggestion}
                                topic={{ id: hit.name, clips: hit.clips }}
                                width={Math.min(538, props.width) - 44}
                            />
                        </div>

                    </div>
                    :
                    <div></div>
            )
        }

        return (
            <Index indexName="topics">
                <Configure hitsPerPage={3} />
                <div className='searchResults'>
                    {hits.map((hit, i) => (
                        <TopicHit key={i} hit={hit} />
                    ))}
                </div>
            </Index>
        );
    }

    const TopicSuggestionHits = (props) => {
        const { hits, results, sendEvent } = useHits(props);

        const isExactMatch = (hit) => {
            return hit.name === props.query.toLowerCase();
        }

        const SuggestionHit = ({ hit, isLast }) => {
            const searchHit = () => {
                eventLog(4, {
                    'topicId': hit.name,
                });
                props.refine(hit.name);
            }

            return (
                isExactMatch(hit) ?
                    <div></div>
                    :
                    <table style={{ padding: '10px 16px 10px 14px', marginLeft: '20px', borderBottom: isLast ? '' : '0.5px solid rgb(224, 224, 224)' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <button onClick={searchHit}>
                                        <SearchRounded style={{ color: 'rgb(120, 120, 120)', marginRight: '6px', fontSize: '21px', verticalAlign: 'middle' }} />
                                    </button>
                                </td>
                                <td style={{ width: '100%' }}>
                                    <button style={{ textAlign: 'left', width: '100%' }} onClick={searchHit}>
                                        <div style={{ fontSize: '16px' }}>
                                            <Highlighter
                                                highlightClassName="ais-Highlight-highlighted"
                                                unhighlightClassName="ais-Highlight-nonHighlighted"
                                                searchWords={[props.query]}
                                                autoEscape={true}
                                                textToHighlight={hit.name}
                                            />
                                        </div>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
            )
        }

        return (
            <Index indexName="topics">
                <Configure hitsPerPage={3} />
                <div className='searchResults'>
                    {hits.map((hit, i) => (
                        <SuggestionHit key={i} hit={hit} isLast={i === hits.length - 1} />
                    ))}
                </div>
            </Index>
        );
    }

    const SearchSpeakers = (props) => {
        const { query, refine, clear } = useSearchBox(props);
        return (
            <Index indexName="speakers">
                <Configure hitsPerPage={1} />
                <SpeakerHits query={query} authenticated={props.authenticated} />
            </Index>
        );
    }

    const SpeakerHits = (props) => {
        const { hits, results, sendEvent } = useHits(props);

        return (
            <Index indexName="speakers">
                <Configure hitsPerPage={1} />
                <MaybeShowPerson query={props.query} hits={hits} authenticated={props.authenticated} />
            </Index>
        )
    }

    return (
        <div className='mainWithBottomMenu'>
            {!initialQuery ? noQueryView :
                <InstantSearch
                    indexName="clips"
                    initialUiState={{
                        "clips": {
                            query: initialQuery ?? '',
                        }
                    }}
                    searchClient={searchClient}>
                    {/* <div>
                    <SearchBoxWithSuggestions fixToTop={true} querySelected={querySelected} initialQuery={initialQuery ?? ''} />
                </div> */}
                    <div style={{ padding: '60px 0px 20px 0px' }}>
                        <SearchTopicSuggestions navigateToUrl={navigateToUrl} width={width} playSuggestion={playSuggestion} />
                        <SearchSpeakers authenticated={authenticated} />
                        <SearchTopics navigateToUrl={navigateToUrl} width={width} playSuggestion={playSuggestion} />
                        <Configure hitsPerPage={20} />
                        <ClipHits
                            playSearchResult={playSearchResult}
                        />
                    </div>
                </InstantSearch>
            }
            <BottomHomeMenu />
        </div>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
})

const mapActionsToProps = {
    setNextClipsInfo,
}

export default connect(mapStateToProps, mapActionsToProps)(AlgoliaSearch);