import axios from 'axios';

import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';

// Redux.
import { connect } from 'react-redux';
import { logoutUser } from '../redux/actions/userActions';

// Router.
import { Navigate } from 'react-router-dom';

// Firebase.
import firebase from 'firebase/compat/app';
import { getAnalytics, logEvent } from "firebase/analytics";
import 'firebase/compat/auth';

// Components.
import BottomHomeMenu from '../components/BottomHomeMenu';

import { clearAllTipsOnLogout } from '../constants/local_storage_constants';
import { validTokenExists } from '../firebaseHelper';


const Menu = ({
    authenticated,
    phoneNumber,
    logoutUser,
}) => {

    useEffect(() => {
        const analytics = getAnalytics();
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'menu'
        });
    }, []);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            if (!!user) {
                // Expected to be signed in.
            } else {
                logoutUser();
                const analytics = getAnalytics();
                logEvent(analytics, 'logout');
            }
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, [logoutUser]);

    const logoutClick = () => {
        clearAllTipsOnLogout();

        firebase.auth().signOut().then(() => {

        }).catch((error) => {
            // An error happened.
        });
    }

    const [feedback, setFeedback] = useState('');
    const [feedbackSuccess, setFeedbackSuccess] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (feedback.trim()) {
            console.log('Feedback submitted:', feedback);
            validTokenExists()
                .then(() => {
                    axios
                        .post('/feedback', {
                            text: feedback,
                        })
                        .then((res) => {
                            if (res.data.error) {
                                window.alert('Error: ' + res.data.error);
                            } else {
                                setFeedback('');
                                setFeedbackSuccess(true);
                            }
                        })
                        .catch((err) => {
                            window.alert('Error: ' + err.response.data.error);
                        });
                })
                .catch((e) => {
                    // Not logged in.
                });
        }
    };

    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    }, []);


    if (!authenticated) {
        return <Navigate to={'/login'}></Navigate>
    } else {
        return (
            <div className="container bottomPadding">
                <BottomHomeMenu />
                <div className='mainWithBottomMenu'>
                    <table style={{ padding: '20px', width: '100%' }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '100%' }}>
                                    <div style={{ fontSize: '15px' }}>
                                        {phoneNumber}
                                    </div>
                                </td>
                                <td style={{ minWidth: '120px', textAlign: 'right' }}>
                                    <button onClick={logoutClick} style={{ color: 'rgb(120, 120, 120)', textAlign: 'left', fontSize: '15px' }}>
                                        Log out
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='thinRightDivider' />

                    {feedbackSuccess ?
                        <div style={{ padding: '40px', textAlign: 'center' }}>
                            <button onClick={() => { setFeedbackSuccess(false) }}>

                                <div style={{ fontSize: '18px', fontWeight: '500' }}>
                                    Thanks for your feedback!
                                </div>
                                <div style={{ fontSize: '60px', padding: '20px'}}>
                                🙏
                                </div>
                            </button>
                        </div>
                        :
                        <div style={{ padding: '20px', textAlign: 'left' }}>
                            <span style={{ fontSize: '18px', fontWeight: '500', verticalAlign: 'middle' }}>
                            We're all ears for any feedback
                            </span>
 
                            <div style={{ marginTop: '20px' }}>
                                <form onSubmit={handleSubmit}>
                                    <textarea
                                        ref={textareaRef}
                                        className='feedbackTextArea'
                                        value={feedback}
                                        onChange={(e) => setFeedback(e.target.value)}
                                        placeholder="How's your experience so far?"
                                        required
                                    />
                                    <div>
                                        <button className='feedbackSubmit' type="submit">Submit Feedback</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

Menu.propTypes = {
    authenticated: PropTypes.bool.isRequired
}

Menu.defaultProps = {

}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    phoneNumber: state.user.phoneNumber,
})

const mapActionsToProps = {
    logoutUser,
}

export default connect(mapStateToProps, mapActionsToProps)(Menu);