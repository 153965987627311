import { ArrowCircleRight } from "@mui/icons-material";
import { ACCENT_COLOR } from "../constants/colors";
import useWindowDimensions from "./windowHelper";
import { PREVIEW_CARD_HEIGHT, PREVIEW_NEXT_CLIP_BUFFER } from "../constants/sizes";

const GetStartedCard = () => {
    const { _, width } = useWindowDimensions();

    return (
      <div style={{
         width: `${Math.min(width, 540) - PREVIEW_NEXT_CLIP_BUFFER}px`,
         height: `${PREVIEW_CARD_HEIGHT}px`,
         background: `${ACCENT_COLOR}28`,
         borderRadius: '10px',
      }}>
        <div style={{ fontSize: '30px', fontWeight: '700', paddingTop: '80px', paddingBottom: '30px'}}>
            <div>
                Discover
            </div>
            <div>
                New
            </div>
            <div>
                Podcasts
            </div>
        </div>
        <span className='animate__animated animate__zoomIn animate__delay-1s' style={{ border: `1px solid ${ACCENT_COLOR}50`, borderRadius: '30px', display: 'inline-block', padding: '8px 16px' }}>
            <ArrowCircleRight style={{ verticalAlign: 'middle', color: ACCENT_COLOR, fontSize: '24px' }} />
            <span style={{ verticalAlign: 'middle', color: ACCENT_COLOR, fontSize: '16px', fontWeight: '600', margin: '0px 8px'}}>
                Start Here
            </span>
        </span>
      </div>
    );
  };
  
  export default GetStartedCard;
  