import Drawer from '@mui/material/Drawer';
import AudioPlayer from './AudioPlayer';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const AudioPlayerDrawer = ({ open, onClose, source, trackId }) => {
    const { state } = useLocation();
    useEffect(() => {
        if (state && !!state.closeAllModal) {
            onClose();
        }
    }, [state])

    return (
        <Drawer
            anchor='bottom' open={open}>
            <AudioPlayer close={onClose} source={source} trackId={trackId} />
        </Drawer>
    );
};

export default AudioPlayerDrawer;