import { connect } from 'react-redux';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react'

import {
    setNextClipsInfo,
    setTrack,
} from '../redux/actions/dataActions';

import { getAnalytics, logEvent } from "firebase/analytics";
import Loading from '../components/Loading';
import axios from 'axios';
import { loadTrack } from '../components/audioHelper';
import { shuffle } from 'lodash';

const Clip = ({
    setNextClipsInfo,
    setTrack,
}) => {
    const navigate = useNavigate();
    let params = useParams();
    const analytics = getAnalytics();

    useEffect(() => {
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'clip'
        });
    }, [analytics]);

    useEffect(() => {
        document.getElementById('audio').pause();
        if (!!params.trackId && !!params.messageIndex) {
            axios
                .get(`/clip/${params.trackId}/${params.messageIndex}`)
                .then((res) => {
                    const data = res.data;
                    playClip(data)();
                })
                .catch((err) => {
                    console.log(err);
                    navigate('/missing');
                });
        }
    }, [params]);

    const playClip = (clip) => () => {
        if (clip) {
            setTrack(clip);
            const fullTrackId = clip.fullTrackId;
            const messageIndex = clip.transcript[0].messageIndex;
            const clipInfo = {
                fullTrackId: fullTrackId,
                messageIndex: messageIndex,
                summary: clip.summary,
                id: `${fullTrackId}_${messageIndex}`,
                title: clip.title,
                mp3URL: clip.mp3URL,
                totalTimeSeconds: clip.totalTimeSeconds
            }
            const addedClipIds = new Set();
            const nextClips = [];
            addedClipIds.add(clipInfo.id);
            if (clip.relatedTopicClips) {
                for (let i = 0; i < clip.relatedTopicClips.length; i++) {
                    const clips = clip.relatedTopicClips[i].clips;
                    for (let j = 0; j < clips.length; j++) {
                        const c = clips[j];
                        if (!addedClipIds.has(c.id)) {
                            nextClips.push(c);
                            addedClipIds.add(c.id);
                        }
                    }
                }
            }
            loadTrack(clip.mp3URL, 0);
            setNextClipsInfo([clipInfo].concat(shuffle(nextClips)));
            navigate('/clips');
        }
    }

    return (
        <div>
            <div className='center'><Loading /></div>
        </div>
    );
};

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    setNextClipsInfo,
    setTrack,
}

export default connect(mapStateToProps, mapActionsToProps)(Clip);