
import { connect } from 'react-redux';
import { saveTrack } from '../redux/actions/userActions';
import { forwardRef, useState } from 'react'
import { getAnalytics, logEvent } from "firebase/analytics";

import Modal from '@mui/material/Modal';

import LoginButtonPopup from './LoginButtonPopup'
import { Bookmark, BookmarkBorder } from '@mui/icons-material';
import { TIP_SAVED_EPISODE } from '../constants/local_storage_constants';
import ButtonTipPopup from './ButtonTipPopup';
import { BookmarkRounded } from '@material-ui/icons';


const SavedEpisodePopupForwardRef = forwardRef((props, ref) => (
    <ButtonTipPopup {...props} />
));


const BookmarkButton = ({
    // Redux.
    authenticated,
    savedTrackIds,
    saveTrack,

    buttonClassName,
    iconClassName,
    iconStyle,
    trackId,
}) => {

    const analytics = getAnalytics();

    const isTrackSaved = () => {
        const savedTrackIdSet = new Set(savedTrackIds);
        return savedTrackIdSet.has(trackId);
    }

    const saved = isTrackSaved();

    const [openLoginButtonPopup, setOpenLoginButtonPopup] = useState(false);

    const [showSavedEpisodePopup, setShowSavedEpisodePopup] = useState(false);

    const setShowSavedEpisodePopupFn = (open) => () => {
        setShowSavedEpisodePopup(open);
    }

    const toggleLoginButtonPopup = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenLoginButtonPopup(open);
    }

    const saveTrackClick = () => {
        if (!authenticated) {
            setOpenLoginButtonPopup(true);
            return;
        }

        if (!saved && !localStorage.TIP_SAVED_EPISODE) {
            localStorage.setItem(TIP_SAVED_EPISODE, true)
            setShowSavedEpisodePopup(true);
        }

        logEvent(analytics, !saved ? 'bookmark_true' : 'bookmark_false');
        saveTrack(trackId, !saved);
    }

    const LoginButtonPopupForwardRef = forwardRef((props, ref) => (
        <LoginButtonPopup {...props} />
    ));

    const icon = saved ? <Bookmark style={iconStyle} /> : <BookmarkBorder style={iconStyle} />;

    return (
        <span>
            <Modal
                open={openLoginButtonPopup}
                onClose={toggleLoginButtonPopup(false)}
            >
                <LoginButtonPopupForwardRef close={toggleLoginButtonPopup(false)} />
            </Modal>
            <Modal
                BackdropProps={{ style: { backgroundColor: 'rgb(0, 0, 0, 0)' } }}
                open={showSavedEpisodePopup}
                onClose={setShowSavedEpisodePopupFn(false)}
            >
                <SavedEpisodePopupForwardRef close={setShowSavedEpisodePopupFn(false)} content={(
                    <div>
                        <div>
                            <BookmarkRounded style={{ fontSize: '80px' }} />
                        </div>
                        <div style={{ marginTop: '20px', fontSize: '24px', fontWeight: '600' }}>
                            Saved Episode
                        </div>
                    </div>
                )} />
            </Modal>

            <button onClick={saveTrackClick} className={buttonClassName}>
                {iconClassName ?
                    <div>
                        <div className={iconClassName}>
                            {icon}
                        </div>
                        {saved ? 'Saved' : 'Save'}
                    </div>
                    :
                    icon
                }
            </button>
        </span>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    savedTrackIds: state.user.savedTrackIds,
})

const mapActionsToProps = {
    saveTrack,
}

export default connect(mapStateToProps, mapActionsToProps)(BookmarkButton);
