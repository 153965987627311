import { Close } from "@material-ui/icons";

const CloseFooter = ({ onClick }) => {
    return (
        <div className='footer'>
            <div className='horizontalCenter'>
                <div style={{ borderTop: '1px solid rgb(224, 224, 224)' }}>
                    <button onClick={onClick} className='closeFooterButton'>
                        <Close />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CloseFooter;  