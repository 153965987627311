import { useRef, useState } from 'react'

import { fancyTimeFormatWithHM, getDateString } from './timeHelper';
import BookmarkButton from './BookmarkButton';
import AudioPlayerDrawer from './AudioPlayerDrawer';
import { loadTrack } from './audioHelper';
import { eventLog } from './eventHelper';
import { useNavigate } from 'react-router-dom';

const SpeakerTrack = ({ speakerId, track, authenticated }) => {

    const navigate = useNavigate();

    const [showAudioPlayerDrawer, setShowAudioPlayerDrawer] = useState(false);

    const playSpeakerTrack = (track, startSeconds) => () => {
        const trackId = track.track_id;
        loadTrack(track.mp3URL, startSeconds);
        setShowAudioPlayerDrawer(true);
        if (!!speakerId) {
            eventLog(2, {
                'speakerId': speakerId,
                'trackId': trackId,
            });
        }

    };

    const closeAudioPlayer = () => {
        setShowAudioPlayerDrawer(false);
        document.getElementById('audio').pause();
    };

    const goToPodcast = (podcastId) => ()  => {
        navigate("/podcast/" + podcastId);
    }

    return (
        <table style={{ paddingTop: '10px', paddingRight: '20px' }}>
            <AudioPlayerDrawer
                open={showAudioPlayerDrawer}
                onClose={closeAudioPlayer}
                source={track.source}
                trackId={track.track_id} />
            <tbody>
                <tr>
                    <td style={{ paddingRight: '20px' }}>
                        <button style={{ width: '100%', textAlign: 'left' }} onClick={goToPodcast(track.source.id)}>
                            <img alt='source' src={track.source.imageURL} style={{ verticalAlign: 'middle', width: '60px', borderRadius: '8px' }} />
                        </button>
                    </td>
                    <td style={{ width: '100%' }}>
                        <button style={{ width: '100%', textAlign: 'left' }} onClick={playSpeakerTrack(track, 0)}>
                            <div className='speakerTrackTitle'>
                                {track.source.title}
                            </div>

                            <div style={{ fontWeight: '400', marginTop: '2px', fontSize: '13px', color: 'rgb(120, 120, 120)' }}>
                                {fancyTimeFormatWithHM(track.totalTimeSeconds)}
                                <span style={{ padding: '0px 8px' }}>·</span>
                                {getDateString(track.publishedDate)}
                            </div>
                        </button>
                    </td>
                    {authenticated ?
                        <td style={{ minWidth: '48px', textAlign: 'right' }}>
                            <BookmarkButton trackId={track.track_id}
                                buttonClassName='clipCardBookmarkButton'
                                iconStyle={{ verticalAlign: 'middle', color: '#202124', fontSize: '25px' }} />
                        </td>
                        :
                        <></>}
                </tr>
            </tbody>
        </table>
    );
};

export default SpeakerTrack;