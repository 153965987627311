import { useEffect, useRef, useState } from 'react'
import useWindowDimensions from '../components/windowHelper';
import { shuffle } from 'lodash';

export default function PodcastFlipper({
    loadingText = '',
    onFinished,
}) {
    const { width } = useWindowDimensions();
    const [progressIndex, setProgressIndex] = useState(0);
    const [srcImages, setSrcImages] = useState([]);

    const _COUNTDOWN_SECONDS = 7;
    const countdownRef = useRef(null);

    const _MAX_IMAGES = 156;
    const _MAX_COLS = 13;
    const _REFRESH_SECONDS = 1;
    

    const allSrcImages = [
        'a52712b6-da3f-4c13-a096-cad1291361b0',
        'cd60d5f9-47e2-4448-8c29-daa5223351a3',
        '8fbd5216-8a62-4d11-bb07-32f3373fb852',
        'f000ce50-bc56-4712-a452-83aa6053f257',
        'abf7898f-0266-4947-9795-780dd1d95813',
        'e729ae39-ebbe-46ad-a1e5-82195b0ecaa7',
        '25ab3974-1033-4943-9ba9-747adf9ee14e',
        'ffbb2110-84b3-428d-a587-c4679cf0a30b',
        '2f52955e-53f0-4a0f-b1af-45d03187c9e6',
        '43b95702-af3d-4e78-b1c2-886fd0ee5427',
        '2181ccca-1911-43b0-b0bb-ef1c9716176f',
        '50921d62-175b-4ec9-ae81-6b6533df5cf1',
        '3cfb4467-8c47-4e76-8a6f-7a2ec1142f41',
        '4b996eb7-976c-4007-92ac-bd4bb9e26355',
        'd6a67f25-64ab-41d6-9d44-46603f162c21',
        'dd1d9d43-766d-416a-9278-f751b8d4593e',
        'e9d6940b-5654-444c-aaab-8c5182fcc543',
        '7239c230-dc71-4eb0-b15c-f3a01dbd947c',
        'b3727450-96dc-40a9-a187-0cabe4d8e2e2',
        'a8226e47-5312-42f9-92fd-5285ca41aa63',
        '179085d7-f332-450e-bfce-eaafceeaa7d3',
        '968f2145-59ec-4219-ada8-76a997024722',
        'b2be1523-5e2d-4a1a-80f6-6d8c992473db',
        '0daaf19f-52f7-4bde-86da-157d2fdf6945',
        '57d546ba-4d43-434c-a56a-e9616fc2213d',
        '9e4be454-3799-4352-b288-c8759a713ea2',
        'f48379ff-a62a-4ced-a25d-dcc787dd3420',
        'f82232a6-0c4c-4150-b9e7-6de5f84663e1',
        '02f31801-b5d1-4a39-aeee-32063c3c3531',
        '20fe222c-a16a-4b17-8984-f3ca98f7255a',
        '60c57ea0-01f9-4e3e-a117-33c325eea61e',
        '4f3d40de-7ba9-4a7e-a8cb-891cc9159f59',
        '6b674844-a60d-4530-9406-c89c3e613999',
        'dc4fe8b9-1a00-4aff-9e5f-5336befd37f8',
        '4709ae75-8073-4428-8edf-7bf8c29b9308',
        '133f149f-0d8b-4083-98e7-5cf21aaa3c90',
        'b2953381-9450-4505-ae09-cca1bf3405a5',
        '3c8c6d08-7324-4369-b404-a95165bca5f7',
        '0b2e29cb-3c34-4ad3-aab8-4ac22d785222',
        'ee9346ab-0410-43f0-8336-f84305e8f15a',
        'c211cff4-199c-4787-ac0a-b74166257926',
        '709b00b4-9983-40e3-8907-f8a701deb1d0',
        'eea90659-e8c4-45fe-b175-513097a80c4c',
        '69da3635-a2d4-48a5-8c7a-ab0a1988c98e',
        '9ada6bb0-925e-4a7d-a868-bdc107a0b5ec',
        '07e77e26-e8ac-4244-84e6-9c9763d68588',
        '7adf9f7c-8c50-49d5-b63d-d1fee6d7acd3',
        'a20c0d84-e5b0-486e-8ecc-c72b6ff0c365',
        '66a7d58c-545b-41e6-ba84-86b8b1feefe9',
        'ee2cfc25-52f8-49eb-a669-4d1709043942',
        'e66af475-9004-457a-89dd-3345767dc4bb',
        'bfa6d1db-679d-4f4a-b583-8c542d129963',
        'b2802253-f0d4-475c-ac07-52bef39f5c57',
        'f3f13cf6-13bd-41a3-9360-a0984a0c0e1f',
        'cf4d45f4-87bd-4535-b53e-c415b8c370a0',
        'df941af3-1228-4c8a-b1a1-eeb399871510',
        'b32296ca-a5cc-48a1-bd59-79db9a2e99ee',
        '185830f2-6071-42e3-9e4a-df9373a9b3ee',
        'ecdb3375-a1a0-411b-874b-fa2db9ab9cd2',
        '6996cb87-2a7c-4c5a-845e-e0598c2d744e',
        'bf336b5c-0fbc-4b72-9f64-b63808d7197e',
        '7c948923-066c-4ae7-896a-a5f367b4beac',
        'd9e4f3a6-bd69-4acf-a087-75cfc96286ff',
        '4753cf2e-5254-4346-96fd-5af38005b886',
        '580b1874-862e-4ff5-a0ff-40bc89feaa6b',
        'a5aa4ecf-3baf-479e-a219-2e24ddf8f55b',
        'b081779e-d1c9-4d27-b65c-fb4f137562c2',
        '0c1bc2ec-0b35-46f0-9a92-e8b6bfe6e1ff',
        '493d17d4-cc10-4fbb-a6a9-e3ddc56c5d5e',
        '603d1263-8dc9-47c2-ace5-f4ea6b3942f8',
        'a76675ad-1d89-4cdf-b0b9-80d6334b4ceb',
        'd875a13c-fa21-4509-bc20-076c87a38a3e',
        '95e733fa-c3d5-4c9d-b490-3be67bd138b7',
        'a2626e77-8a4a-4166-afb4-a4bcd0144e03',
        '19732d68-4098-4a30-a6a9-8e5b744e8e59',
        '00ea3b4a-3b6b-4424-9f66-6ff514fa07e0',
        'b9dff5ea-1717-4bea-a184-67a118943169',
        '175192fc-1234-4d66-9615-9f5238f504e7',
        '721e5318-daad-44fe-a0b8-66c92da372b2',
        '84b63cf7-3d07-4de1-80cc-b63977b00918',
        'f23450b6-a172-4f32-8993-11369421eac7',
        'bf95a392-ebbb-4bbd-aa72-14961888ef18',
        'c7c9e0b5-69b9-43b1-b309-3f6e185533b5',
        '92df79e0-33c4-420b-89dc-4494791a2b5b',
        '0e010e70-ce6e-48b1-8867-750c21b17b94',
        'a1b7e8ad-7c6d-4dc7-b67d-490251dfcf3a',
        'cb2d8f58-0495-4f30-9424-8c27122f809d',
        'cff9f523-8b22-4603-890d-fc4c80f34ce7',
        '06a772b0-7351-480b-abb3-3768d4b6daa4',
        'c1992236-0f20-4a42-8242-dcf02f0f1819',
        '1fcd2391-9e83-4b79-98ab-700988eeb863',
        '0e40db3f-b735-434a-b418-97f417921115',
        '941b1bf0-6881-48e2-bdc7-3c1c0f4f080a',
        'd7d34f2a-3946-4d74-b270-e8d96a563550',
        'e5c6811d-f94b-4e5d-a5f6-bbca068e66bf',
        '790f2874-8446-4b1d-adf0-7ec52704447f',
        '74a901d6-cce7-4373-8783-39a066eaaaf8',
        'a00db2b1-ce76-47d4-b7ba-d58fc3e0e8de',
        'd768a944-00b9-4d18-aaab-59b029c4a4d6',
        '50842224-5179-46cb-bebf-b5125b1925e9',
        '7dd65c7f-5362-4d15-9c9a-c749de2ecc18',
        'a969a6dd-8cfa-4089-bf17-07deab0ef635',
        '66afe80a-28bf-452e-9268-29b191b958ed',
        '04845b83-3de5-4620-a480-a743f57c49a2',
        '1aa5f9ea-ceab-4cde-b821-63eba78b645d',
        'cd525064-6639-4536-8e17-e82fc724771e',
        'f6c98877-0aff-41db-a873-6c4569896e66',
        '97c460a4-a39d-4af0-844d-5cc28eb8b57e',
        '7acbb099-f2ec-4fa7-83e9-94d0967fbf57',
        'c24ec6df-604c-420a-b626-06e6bb6104f8',
        '97c9e508-4993-4275-a192-f3ca9be3cdfc',
        '33fcf228-b313-4d68-82db-a7fdd030ea98',
        '8e316ef5-1393-48d8-a5a4-065c5b995cf9',
        'a2959d29-30df-4804-a75f-939ad92d08ec',
        '0e614988-9f12-4f08-9d64-658252a63495',
        '57580f58-9183-45bd-b28d-e9c3e9f79787',
        '3bbb32ea-538f-4dbd-ab15-5e5b9d4c5b3a',
        '5b94f888-914f-4cf7-bcfc-845ee4287cad',
        '2b0ca18d-0ca4-4fb9-950c-c32350af699e',
        'fee480dd-3c0f-40aa-9138-91728402b68b',
        'b3e5caed-c0a2-4bdf-b2ca-b20de7136225',
        '273904b4-891c-4ddf-a8a7-c07fcc7a8d8e',
        'd6f1b9df-b9f2-4625-8ca9-c27e3104492b',
        '027d2e42-1b50-4498-b21c-8686cd34d8be',
        '17733112-02dc-4858-aed9-713709d6c16c',
        '934c8c9c-ebf2-42f6-8db5-ee56dd7a5594',
        '2fd93764-276a-46d7-a12c-11b187afe18a',
        '57055fe1-c069-4d46-a249-ec8fd7bc2724',
        '0141d5d9-1d4b-402a-910c-997f3e0186fd',
        'f440d33c-b890-4b09-b350-53156047b3c7',
        'f23b0ec7-30d8-4571-bbca-a089890e1fc3',
        '5efa8701-b96c-400e-a010-3731ab46b0c2',
        'c42c518e-2782-42b5-8805-110ebb5838ad',
        'c19d8d9e-cd75-496a-9e74-efda6fc4f67b',
        'c6a5634b-55b7-4a52-94eb-4950cad56d33',
        'ae1f11f0-f6a7-45f9-9d14-518d30c29db3',
        '6fd47638-e76f-4c15-87f3-6c36886c72c8',
        'a6169406-dc99-46b5-b103-6fc9559692fe',
        'ce51090c-522d-4a89-a99e-66a39a851f3c',
        'a8c86a9d-a0e6-4007-b310-ea5831cab482',
        '569225a4-21df-4114-bbf1-0d92d1c4347f',
        'c0fcf4e4-b3df-49d2-aaa3-0700323d6ff9',
        '37ae4e5f-2e35-4f68-b76b-e1183cf8fabf',
        '7df089b4-c9b3-46d4-98d5-22384380a5b3',
        '4b463e3f-d9de-4da1-825e-4250021e54e0',
        'c2e9d46f-832a-47aa-8abe-59909a382dd6',
        '539f37c0-b32e-4e12-b83d-8f4acb1022dc',
        '91945798-99ae-4425-93c7-58ad95b58292',
        '65b09ce7-cd9b-4392-bf43-c3aa72827399',
        '60397f71-2047-4a8c-823a-5816437de12d',
        'f42b60d5-d431-4fe7-8f2b-df9c0bca4fc3',
        '12f5641b-0f3c-487b-921b-8ba8f06d4900',
        'b4442fc7-288c-4800-9bda-ed93e160b054',
        '9376a835-ffec-424b-97ff-609815450b23',
        '93c41cf4-c039-4a9c-aa8a-ca5be2557c26',
        'd0540ff2-9ec1-41d1-8f30-c0e0e420c6a0',
        'c01cfeb4-c0b2-41f8-82ae-463a555f4476',
        '7e282a9e-89ce-4c13-bdb5-38373f491ef9',
        '6fd168d0-2742-4b3b-8fd3-3857f8201d3e',
        '9040176e-605a-4484-b33a-3a1b5f898b5c',
        '68cc1d38-0084-42fc-b41e-71d6bbc2a28a',
        'c1ded7b6-68b4-4e44-a775-b56f6332b46b',
        '990a2a4d-7386-4e27-8227-b96cd3c3ace8',
        '36a780fb-4b81-463a-b6a0-804968d04c6b',
        '5e945c3e-a740-44b4-8c21-391b6b9a3350',
        '7912d8dc-cd58-4511-a360-9f1dee03c653',
        'b207509b-de87-4d07-80a1-690a2ccb5512',
        '42709900-fced-40b1-a511-a2918574d4d9',
        'ae6b8de7-828c-410f-8322-36ca32aa50da',
        '9648dabd-1288-41e8-8b68-1a4622f439b7',
        'c0ec7010-db98-41f2-82e8-a96cd9c8682a',
        'e9404df2-4747-4a87-82c7-550974694134',
        '99932cd7-8334-479a-b569-56d3f9a20e30',
        '9e4f73ac-e807-45da-82ec-6a0ca198223e',
        '15d36788-a8a0-4ef4-b400-832eb64535da',
        'd6715b0f-d2ea-4ab9-9053-f5a8fce54550',
        '6edbce86-2bb2-4bdb-9f81-190d163bae9a',
        'c9739694-6963-4f44-a493-2e712f3b802e',
        '6bd0541a-9262-47a5-9360-b3762a8583cd',
        '888b2df3-c274-4d1c-8a4a-db8b24461efa',
        '5742765b-3929-4219-bba1-7ba2abc0a002',
        'ee365b44-30dd-45c0-98a6-275e7e9213c9',
        'c859bcf3-f987-4d63-9c59-09ec5269ebf8',
        '028fb25f-5cc2-4362-95e2-5dae79d064c1',
        '3561be9a-2233-4e81-accf-8499323bc304',
        'b6f62e18-928b-4c5f-adb2-4e4836f7ecaa',
        'c6bc77ef-154b-4ab6-9bce-e294bc790f89',
        '2016bee8-feb2-41bf-be87-728340b2fc32',
        '61091bfb-7949-409a-8fe4-5fc5ada03948',
        '6399e1a6-104a-4fae-aff2-af6453d4eb9b',
        'fad27040-844e-457d-8c11-0d0ed7f3b2e1',
        'a21d74a5-a3e5-44e2-a81f-4af89f02cc5d',
        '1be10caa-239c-45cc-a634-55b91a52d1b2',
        '70722d99-fd02-4df6-bbe5-5f7ca82f06a7',
        'e2e03ffa-d397-4762-aa93-bf14de7d46f6',
        '0f1125be-0891-48dc-ac4d-bdd51b05f7b9',
        '7d0cec06-0e2b-475b-9d06-4e3348a853e7',
        'c98307d9-d13d-4857-8134-bea11f2573aa',
        '8f259c8b-8524-466f-bfa6-cfe069cdb590',
        '4cab00ee-9cc6-4b72-b7e2-1f5454c19b34',
        'e426f812-c58b-467a-85e3-8207e9e40458',
        '413d8358-3f01-4c5f-a10c-aea5eb2691a2',
        '27e44cd4-04ef-49a2-90c8-39d2cbbad00a',
        'ffea9470-e926-46a4-9628-82b5345c364a',
        '6a1b6ee8-9315-49ec-832d-f16de59ac933',
        '8e19fd20-6758-4fb7-87a8-2c327d255d26',
        '1249dc5b-33e6-4a8b-a24f-6fe8f66a581a',
        'f1ea627e-4f1e-4d1f-994a-d68bc08c0339',
        '356d73d7-39b7-4ddb-90e2-ddc1e7e5922d',
        'e98b6f45-319c-48ff-a723-1f5ff0cd3caa',
        'b6fe7321-22a9-4ec4-8f36-5f5e392cb7e3',
        'b5264bd0-dc92-4f56-9c2b-3078a488598d',
        '3ccce96d-6738-4a87-8dcd-7704704f07fc',
        '6bae1141-edf7-4120-a35f-5afae3733633',
        'b6855fd6-0049-48ff-acc4-31e3c9b49225',
        '5fef4d4b-5144-43b6-9f4a-1a897f221565',
        '72557e21-2dd0-4314-85d8-46226febf3f9',
        'c9a2f367-bd37-412d-a351-8fe34fd1d57b',
        'dd9a997d-8d0f-4d6d-9c8b-0dadbf4439e1',
        '2a332310-a5fe-4bf7-9eb4-2036cbf49ed6',
        '418cc4df-2459-4f61-af73-313ac3323918',
        'c318d5d4-8963-475e-acc0-7eb180604ee6',
        'f1cdc58d-cc55-4ced-9cd3-5bdd44836a7d',
        'f96a6cf5-0222-4e60-8290-dfa17fca2208',
        '705487e8-577b-4b3e-a076-244ce3489603',
        'c6d5d1b7-857e-438c-876b-3f3aaa53a1b3',
        '9bcaa6b4-8d1a-4a74-87bf-10dc27387612',
        '7a9a102b-702f-42fd-8672-5ef7ef0c03e6',
        '76e3b2f7-7899-43af-953e-6333336085e2',
        '6ba1b3dd-7ea6-4387-a729-3ad89f22d8c8',
        'd48d1451-696f-4b4e-abb7-43dbcc793889',
        'ea62a389-6e5d-4b5b-8691-5266ae2e3aac',
        '3ae22bef-a969-43fc-b960-421a2b06d7cc',
        '7f874fd1-2f7a-45da-9283-5797897acc47',
        '0d4d765c-b9d1-4166-82df-6132eb32ffdb',
        '640ec5b0-558d-4301-b216-4f059595205a',
        '3feecddc-ce6f-4ebc-83ad-7115e04d33bc',
        '12042692-438d-438a-b2a1-c7852596af40',
        '9e207e7c-bd00-4330-ad7d-d882fcf3e3cc',
        'c7836eba-38d0-446e-8e82-f1cdcaab8fed',
        '51c5732e-5c85-4a2a-b9eb-3d2966e5f7ac',
        'bd71e28d-3213-47b8-9e31-8b7cf42c41c8',
        'edd93412-8786-4ed0-8241-45b439713eb4',
        '9c85c68b-c095-4862-bb01-41675d68e1f5',
        '44792ad6-4df3-466d-8e94-5773eb8ace35',
        '24beb546-e23a-45bf-95e3-e16b99baabab',
        '3745ea1e-53d4-489b-861b-6122ff6bea5e',
        'bc10c70e-4fe6-4e22-b8e5-f81e583c956c',
        '981999e9-0d37-4e4c-b315-e12357b5a48b',
        '0cfa003c-f4c0-452c-ad70-a11b97a20d68',
        '69e6b375-640f-45c9-bbf3-5749ac5cf81f',
        '8cc262ba-8cdc-4862-8b53-75a3e76a795b',
        '318b737e-e4b0-4482-821c-757cc3b33d99',
        '7a2ce3b4-8e96-4269-abf6-dc451d32848f',
        '9747b1dc-43bb-4f73-b57c-6affaaa0065c',
        '44c36d6f-4de4-453c-ba62-a8867757a269',
        '9db265df-d333-48bc-b415-3a39587f4d47',
        '08519c39-0ad8-4654-b553-816ec3aa78a3',
        '2cd16d6d-89e8-4230-b4a8-fc01063c1593',
        '8156add7-45ec-4414-a15f-70a1f0ffda48',
        'ec9197ae-6f24-4992-9dad-98d595895216',
        '2233c0bb-f073-4e1d-ab76-98d371ed0f4f',
        '3898e861-d81c-4e59-8772-a58da2af3ff4',
        '4aabd07e-59ab-4278-aa3b-d55bc38aa902',
    ];

    const refreshImages = () => {
        const countdownSecondsRemainingPercentage = ((_COUNTDOWN_SECONDS - countdownRef.current - _REFRESH_SECONDS) / _COUNTDOWN_SECONDS)
        setProgressIndex(Math.floor(countdownSecondsRemainingPercentage * _MAX_IMAGES));
    }

    useEffect(() => {
        setSrcImages(shuffle(allSrcImages).slice(0, _MAX_IMAGES));
        countdownRef.current = _COUNTDOWN_SECONDS;
        refreshImages();
        const intervalId = setInterval(() => {
            let newCountdownSecsRemaining = countdownRef.current - _REFRESH_SECONDS;
            if (newCountdownSecsRemaining === 0) {
                newCountdownSecsRemaining = _COUNTDOWN_SECONDS;
                onFinished();
            }
            countdownRef.current = newCountdownSecsRemaining;
            refreshImages();
            console.log(newCountdownSecsRemaining);
        }, _REFRESH_SECONDS * 1000);

        // effect cleanup
        return () => {
            clearInterval(intervalId);
        }
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <div style={{ lineHeight: 0, borderRadius: '8px', maxWidth: '360px', margin: '0px auto', padding: '20px 20px' }}>
                {srcImages.map(((srcId, srcIndex) => (
                    <span key={srcIndex} className='invisible' style={{ lineHeight: '0', display: 'inline-block', height: `${((Math.min(360, width) - 40) / _MAX_COLS)}px`, width: `${((Math.min(360, width) - 40) / _MAX_COLS)}px` }}>
                        {srcIndex > progressIndex ?
                            <></>
                            :
                            <img style={{ height: `${((Math.min(360, width) - 40) / _MAX_COLS)}px` }} className={'animate__animated animate__flipInX'} src={`https://firebasestorage.googleapis.com/v0/b/twinklepoo/o/srcimg%2F${srcId}.png?alt=media`}>
                            </img>
                        }
                    </span>

                )))}
            </div>
            <div style={{ textAlign: 'center', fontSize: '14px', fontWeight: '500', padding: '20px'}}>
                {loadingText}
                <div className="loadingSpinner"></div>
            </div>
        </div>
    );
}
