import { forEach } from "lodash";
import useWindowDimensions from "./windowHelper";
import { PREVIEW_CARD_HEIGHT, PREVIEW_NEXT_CLIP_BUFFER } from "../constants/sizes";

const ClipLargePreviewLoading = ({ }) => {
    const { _, width } = useWindowDimensions();

    return (
        <div className='glow' style={{ width: '100%', overflowX: 'hidden', whiteSpace: 'nowrap' }}>
            {[...Array(2)].map((_, index) => (
                <span
                    style={{
                        display: 'inline-block',
                        width: `${Math.min(width, 540) - PREVIEW_NEXT_CLIP_BUFFER}px`,
                        background: 'rgb(242, 242, 242)',
                        borderRadius: '8px',
                        height: `${PREVIEW_CARD_HEIGHT}px`,
                        marginRight: '10px',
                        marginLeft: index == 0 ? '20px': '0px',
                        position: 'relative',
                    }}>
                        <div style={{ paddingTop: '40px'}}>
                            <div className='loading' style={{ width: '120px', height: '120px', borderRadius: '50%', margin: '0px auto' }}>
                        </div>
                        
                        </div>
                        <div style={{ position: 'absolute', bottom: '0px', padding: '20px', width: '100%'}}>
                            <div className='loading' style={{ padding: '20px', borderRadius: '10px', width: '100%', height: '50px', marginBottom: '10px'}}>
                                
                            </div>
                            <div className='loading' style={{ padding: '20px', borderRadius: '10px', width: '100%', height: '70px' }}>
                                
                            </div>
                        </div>
                </span>
            ))}
        </div>

    );
};

ClipLargePreviewLoading.defaultProps = {

}

ClipLargePreviewLoading.propTypes = {

}

export default ClipLargePreviewLoading;