import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react'

import { getAnalytics, logEvent } from "firebase/analytics";

import EntityInfo from '../components/EntityInfo';

import BottomHomeMenu from '../components/BottomHomeMenu';
import { LABEL_APPNAME, LABEL_TAGLINE } from '../constants/labels';

const Speaker = ({

}) => {

    let params = useParams();
    const analytics = getAnalytics();

    useEffect(() => {
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'speaker'
        });
    }, [analytics]);

    const missingSpeaker = (
        <div style={{ padding: '20px' }}>

            <div style={{ paddingBottom: '20px', marginBottom: '20px', borderBottom: '1px solid rgb(242, 242, 242)' }}>
                <a href='/'>
                    <div>
                        <img className='appName' src='/appname2.png' />
                    </div>
                    <div className='tagline'>
                        {LABEL_TAGLINE}
                    </div>
                </a>
            </div>

            <div style={{ textAlign: 'center' }}>
                <div style={{ padding: '40px 20px' }}>
                    <div style={{ fontSize: '18px', fontWeight: '500' }}>
                        Unknown Speaker
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div>
            <div className="container">
                <div className='animate__animated animate__fadeIn'>
                    <div style={{ background: 'white', paddingBottom: '80px' }}>
                        <EntityInfo speakerId={params.speakerId} missingSpeaker={missingSpeaker} />

                        <BottomHomeMenu />
                    </div>

                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(Speaker);