import { forwardRef, useEffect, useRef, useState } from 'react';

// Redux.
import { connect } from 'react-redux';

import { ContentCopyRounded, IosShareRounded, Star } from '@mui/icons-material';
import { Link, Pause, PlayArrow, SwapVert } from '@material-ui/icons';

// MUI components.
import Modal from '@mui/material/Modal';

// Components.
import ButtonTipPopup from './ButtonTipPopup';
import CloseFooter from './CloseFooter';

import { getSectionTitle } from './sectionHelper';
import { fancyTimeFormat, fancyTimeFormatWithHMS } from './timeHelper';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { LABEL_APPNAME, LABEL_DOMAIN } from '../constants/labels';
import { isIPhone } from './deviceHelper';
import { ACCENT_COLOR } from '../constants/colors';
import { eventLog } from './eventHelper';


const ButtonTipPopupRef = forwardRef((props, ref) => (
    <ButtonTipPopup {...props} />
));

const Sections = ({
    playing,
    close,
    // curTime,
    currentSection,
    currentSectionTimeLeft,
    sectionsContainerRef,
    track,
}) => {
    const audio = document.getElementById('audio');
    const analytics = getAnalytics();

    const [showCurrentSectionButton, setShowCurrentSectionButton] = useState(true);
    const [showCopiedLink, setShowCopiedLink] = useState(false);
    const [popularSections, setPopularSections] = useState(new Set());
    const scrollIntoViewInProgress = useRef(false);
    const scrollTimeout = useRef(null);

    const headingHeight = '68px';

    useEffect(() => {
        if (!showCurrentSectionButton && !scrollIntoViewInProgress.current) {
            const elem = document.getElementById(getSectionId(currentSection));
            if (elem) {
                scrollIntoViewInProgress.current = true;
                elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
                scrollTimeout.current = setTimeout(function () {
                    scrollIntoViewInProgress.current = false;
                }, 500);
            } else {
                // console.log("missing id: " + elementId);
            }
        }
    }, [currentSection, showCurrentSectionButton])

    const calculatePopularSections = () => {
        let sectionIndexToScore = {};
        let totalNumMessages = track.transcript.length;
        for (let i = 0; i < track.sectionInfos.length; i++) {
            const section = track.sectionInfos[i];
            if (section.score > 0.5) {
                // Prefer shorter sections if scores are the same.
                let durationRatio = 1 - (section.numMessages / totalNumMessages);
                // Divide by 100 so value is less than .01
                let durationScore = durationRatio / 100
                sectionIndexToScore[i] = section.score + durationScore;
            }
        }

        const newPopularSections = new Set();
        // Max allow 25% of sections to be marked as popular.
        const maxPopular = Math.floor(track.sectionInfos.length * 0.25)
        let sortedSectionIndexAndScore = Object.entries(sectionIndexToScore).sort((a, b) => b[1] - a[1]);
        for (let i = 0; i < sortedSectionIndexAndScore.length; i++) {
            newPopularSections.add(parseInt(sortedSectionIndexAndScore[i][0]))
            if (newPopularSections.size === maxPopular) {
                break;
            }
        }
        setPopularSections(newPopularSections);
    }

    useEffect(() => {
        calculatePopularSections();

        const onScroll = (e) => {
            clearTimeout(scrollTimeout.current);
            // Set in progress to false once scroll finishes.
            // onScroll will continue updating scrollTimeout when scroll is in progress.
            // when scroll finishes, scrollIntoViewInProgress will be set to false.
            if (scrollIntoViewInProgress.current) {
                scrollTimeout.current = setTimeout(function () {
                    scrollIntoViewInProgress.current = false;
                }, 500);
            } else {
                // If user manually scrolls, disable auto scrolling to current section.
                setShowCurrentSectionButton(true);
            }
        }

        // DOM listeners: update React state on DOM events
        // document.addEventListener('scroll', onScroll);
        sectionsContainerRef.current.addEventListener('scroll', onScroll);

        // effect cleanup
        return () => {
            // clearTimeout(scrollTimeout.current);
            // window.removeEventListener('scroll', onScroll);
            const sectionsContainer = sectionsContainerRef.current;
            if (sectionsContainer) {
                sectionsContainer.removeEventListener('scroll', onScroll);
            }
        }
    }, []);

    const seek = (timeSeconds) => {
        audio.currentTime = timeSeconds;
        audio.play().catch(function () { });
    }

    const getSectionId = (sectionIndex) => {
        return `${track.fullTrackId ?? track.id}_section_${sectionIndex + 1}`
    }

    const getSectionDuration = (sectionIndex) => {
        let sectionEndTime = track.totalTimeSeconds;
        if (sectionIndex + 1 < track.sectionInfos.length) {
            sectionEndTime = track.sectionInfos[sectionIndex + 1].startTime;
        }
        const duration = sectionEndTime - track.sectionInfos[sectionIndex].startTime;
        return fancyTimeFormatWithHMS(duration / audio.playbackRate);
    }

    const shareSection = (sectionIndex) => () => {
        const section = track.sectionInfos[sectionIndex];
        const link = `https://${LABEL_DOMAIN}/track/${track.fullTrackId ?? track.id}?start=${Math.floor(section.startTime)}`;
        if (navigator.share && isIPhone()) {
            let shareText = 'Thought you might like this section:\n\n🎙️ ' + section.title + '\n\n' + section.summary + '\n\n' + link;
            logEvent(analytics, 'section_share');
            navigator.share({
                title: LABEL_APPNAME,
                text: shareText,
            })
                .then(() => { })
                .catch((error) => {
                    console.log('Error sharing', error)
                });
        } else {
            setShowCopiedLink(true);
            navigator.clipboard.writeText(link);
        }
    }

    const sectionClick = (section) => () => {
        seek(section.startTime);
        eventLog(8, {
            'trackId': track.id,
            'sectionStartIndex': section.startIndex,
            'topicIds': section.topics
        });
    }

    const _CELL_VERTICAL_PADDING = '13px';

    const playPauseClick = () => {
        if (playing) {
            audio.pause();
        } else {
            audio.play();
        }
    }

    return (
        <div className='hasCloseFooter' style={{ textAlign: 'left', width: '100%' }}>
            <Modal
                BackdropProps={{ style: { backgroundColor: 'rgb(0, 0, 0, 0)' } }}
                open={showCopiedLink}
            >
                <ButtonTipPopupRef
                    close={() => { setShowCopiedLink(false) }}
                    content={(
                        <div>
                            <div>
                                <ContentCopyRounded style={{ fontSize: '80px' }} />
                            </div>
                            <div style={{ marginTop: '20px', fontSize: '24px', fontWeight: '600' }}>
                                copied link
                            </div>
                        </div>
                    )} />
            </Modal>

            {!track ? <></> :
                <div>
                    <div>
                        <div className='sectionsHeading'>
                            <div className='horizontalCenter' style={{ padding: '16px 20px' }}>
                                <span className='sectionAndTranscriptHeading'>Sections</span>
                            </div>
                        </div>
                    </div>

                    <div style={{ paddingTop: headingHeight, paddingBottom: '100px' }}>
                        {track.sectionInfos.map((section, sectionIndex) => (
                            <div id={getSectionId(sectionIndex)} key={sectionIndex} style={{
                                marginLeft: '20px',
                                // leaving space at top for header for current section autoscroll.
                                paddingTop: headingHeight,
                                marginTop: `-${headingHeight}`
                            }}>
                                <div>
                                    <div style={{
                                        textAlign: 'left', width: '100%'
                                    }} >
                                        <div style={{
                                            color: 'rgb(32, 33, 36)',
                                            width: '100%',
                                            fontWeight: '500',
                                            fontSize: '16px', textAlign: 'left'
                                        }}>
                                            <table style={{ borderSpacing: 0, width: '100%', paddingTop: _CELL_VERTICAL_PADDING }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ verticalAlign: 'top', minWidth: '44px' }}>
                                                            <button onClick={sectionClick(section)}>
                                                                <span style={{ display: 'inline-block', color: 'rgb(120, 120, 120)', fontSize: '16px', fontWeight: '400', paddingRight: '16px' }}>{sectionIndex + 1}</span>
                                                            </button>
                                                        </td>
                                                        <td style={{ verticalAlign: 'top', width: '100%' }}>
                                                            <button style={{ textAlign: 'left' }} onClick={sectionClick(section)}>
                                                                <div style={{ fontSize: '15px', fontWeight: '500' }}>
                                                                    {getSectionTitle(section)}
                                                                </div>
                                                                <div style={{ marginTop: '4px' }}>
                                                                    {!(popularSections.has(sectionIndex)) ?
                                                                        <></>
                                                                        :
                                                                        <span>
                                                                            <Star style={{ marginRight: '6px', color: ACCENT_COLOR, fontSize: '16px', verticalAlign: 'middle' }} />
                                                                            <span style={{ fontSize: '13px', verticalAlign: 'middle', fontWeight: 400 }}>
                                                                                Popular
                                                                            </span>
                                                                            <span style={{ padding: '0px 8px', fontSize: '13px', verticalAlign: 'middle', fontWeight: 400 }}>
                                                                                ·
                                                                            </span>
                                                                        </span>
                                                                    }
                                                                    <span style={{ fontSize: '13px', verticalAlign: 'middle', fontWeight: 400 }}>
                                                                        {getSectionDuration(sectionIndex)}
                                                                    </span>
                                                                </div>
                                                            </button>
                                                        </td>
                                                        <td style={{ verticalAlign: 'top', minWidth: '70px', textAlign: 'right', paddingRight: '20px' }}>
                                                            <button onClick={shareSection(sectionIndex)} style={{ color: 'rgb(80, 80, 80)', padding: '0px 0px' }}>
                                                                {navigator.share ?
                                                                    <IosShareRounded style={{ fontSize: '16px', verticalAlign: 'middle' }} />
                                                                    :
                                                                    <Link style={{ fontSize: '16px', verticalAlign: 'middle' }} />
                                                                }

                                                                <div style={{ marginTop: '3px', fontSize: '12px' }}>
                                                                    {fancyTimeFormat(track.sectionInfos[sectionIndex].startTime / audio.playbackRate)}
                                                                </div>
                                                            </button>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div style={{ borderBottom: '1px solid rgb(245, 245, 245)', paddingTop: _CELL_VERTICAL_PADDING, marginLeft: '44px' }}>
                                                {sectionIndex !== currentSection ? <></> :
                                                    <button style={{ marginBottom: '16px', padding: '8px 16px', background: 'black', color: 'white', borderRadius: '16px' }} onClick={playPauseClick}>
                                                        {playing ? <Pause style={{ fontSize: '20px', verticalAlign: 'middle' }} /> : <PlayArrow style={{ fontSize: '20px', verticalAlign: 'middle' }} />}
                                                        <span style={{ verticalAlign: 'middle', paddingLeft: '4px' }}>
                                                            {currentSectionTimeLeft}
                                                        </span>
                                                    </button>

                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>

                    {showCurrentSectionButton ?
                        <div className='syncSectionContainer'>
                            <button className='syncButton' onClick={() => { setShowCurrentSectionButton(false) }}>
                                <div className='syncButtonDiv'>
                                    <SwapVert style={{ marginLeft: '8px', fontSize: '22px', verticalAlign: 'middle' }} />
                                    <span style={{ verticalAlign: 'middle', padding: '0px 16px 0px 8px' }}>
                                        Auto Scroll
                                    </span>
                                </div>
                            </button>
                        </div>
                        :
                        <></>
                    }

                    <CloseFooter onClick={close} />
                </div>
            }
        </div>
    );
}

Sections.propTypes = {

}

Sections.defaultProps = {

}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(Sections);
