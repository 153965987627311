import { MoreHoriz, PauseRounded, PlayArrowRounded } from "@material-ui/icons";
import Icon from '@mdi/react';
import { mdiFastForward15, mdiRewind15 } from '@mdi/js';
import { useEffect, useState } from "react";
import { fancyTimeFormat } from "./timeHelper";

const PlayPauseBar = ({
    onMoreClick,
}) => {
    const audio = document.getElementById('audio');

    const [playing, setPlaying] = useState(false);
    const [curTime, setCurTime] = useState(0);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [duration, setDuration] = useState(null);

    useEffect(() => {
        const setAudioTime = () => {
            setCurTime(audio.currentTime / audio.playbackRate);
            const remaining = (audio.duration - audio.currentTime) / audio.playbackRate;
            setTimeRemaining(remaining);
            if (!playing && !audio.paused) {
                setPlaying(true);
            }
            setDuration(audio.duration / audio.playbackRate);
        }

        const onPause = (event) => {
            setPlaying(false);
        }

        const onPlay = (event) => {
            setPlaying(true);
        }

        // DOM listeners: update React state on DOM events
        audio.addEventListener('timeupdate', setAudioTime);
        audio.addEventListener('pause', onPause);
        audio.addEventListener('play', onPlay);

        // effect cleanup
        return () => {
            audio.removeEventListener('timeupdate', setAudioTime);
            audio.removeEventListener('pause', onPause);
            audio.removeEventListener('play', onPlay);
        }
    }, []);

    const playPauseColor = 'rgb(30, 30, 30)';

    const togglePlayPause = () => {
        if (playing) {
            audio.pause();
        } else {
            audio.play().catch(function () { });
        }
    }

    const rewindClick = () => {
        audio.currentTime = Math.max(0, audio.currentTime - 15);
    }
    const forwardClick = () => {
        audio.currentTime = Math.min(audio.duration, audio.currentTime + 15);
    }

    function displayPlaybackRate(value) {
        // Convert the number to a string with a maximum of 2 decimal places
        let formattedString = value.toFixed(2);
        
        // Remove trailing zeros after the decimal point
        formattedString = formattedString.replace(/\.?0+$/, '');
        
        return formattedString;
      }

    return (
        <table style={{ borderSpacing: 0, paddingTop: '0px' }}>
            <tbody>
                <tr>
                    <td style={{ minWidth: '67px', textAlign: 'center' }}>
                        <button onClick={togglePlayPause} style={{ verticalAlign: 'middle' }}>
                            {playing ?
                                <PauseRounded style={{ fontSize: '26px', color: playPauseColor, verticalAlign: 'middle' }} /> :
                                <PlayArrowRounded style={{ fontSize: '30px', color: playPauseColor, verticalAlign: 'middle' }} />
                            }
                        </button>
                    </td>
                    <td style={{ width: '100%' }}>
                        <div className="play-pause-progress">
                            <div className="progress-bar">
                                <div className="progress-bar-bg"></div>
                                <div className="progress-bar-fg" style={{ width: `${curTime / duration * 100}%`}}></div>
                                <div className="progress-bar-labels">
                                    <button onClick={rewindClick} style={{ float: 'left' }}>
                                        <Icon path={mdiRewind15} size={0.8} style={{ verticalAlign: 'middle'}} />
                                    </button>
                                    <span style={{ verticalAlign: 'middle', fontSize: '13px' }}>
                                        {`-${fancyTimeFormat(timeRemaining)}`}
                                    </span>
                                    <button onClick={forwardClick} style={{ float: 'right' }}>
                                        <Icon path={mdiFastForward15} size={0.8} style={{ verticalAlign: 'middle'}} />
                                    </button>
                                </div>
                            </div>
                        </div>


                    </td>
                    <td style={{ minWidth: '67px', textAlign: 'center' }}>
                        <button onClick={onMoreClick} style={{ verticalAlign: 'middle' }}>
                            <div style={{ position: 'relative', verticalAlign: 'middle' }}>
                                <div style={{ marginBottom: '-11px', fontSize: '13px', fontWeight: '500' }}>{displayPlaybackRate(audio.playbackRate)}x</div>
                                <MoreHoriz style={{ marginBottom: '-11px', fontSize: '24px', color: playPauseColor }} />
                            </div>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default PlayPauseBar;
