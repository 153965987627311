// Redux.
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
    loadForMe,
} from "../redux/actions/userActions";
import {
    setNextClipsInfo,
} from "../redux/actions/dataActions";
import { useNavigate } from "react-router-dom";
import { loadTrack } from "./audioHelper";
import { eventClipView, eventLog } from "./eventHelper";
import ClipLargePreviewLoading from "./ClipLargePreviewLoading";
import { Assistant, NavigateBefore, NavigateNext, Settings, Star, TuneRounded } from "@material-ui/icons";
import ClipLargePreview from "./ClipLargePreview";
import { RESPONSIVE_LARGE_WIDTH } from "../constants/sizes";
import useWindowDimensions from "./windowHelper";
import GetStartedCard from "./GetStartedCard";


const ForMePreview = ({
    // Redux.
    authenticated,
    forMeData,
    forMeLoadingCount,
    getStartedTopicsStatus,
    loadForMe,
    setNextClipsInfo,
}) => {

    const [selectedTopic, setSelectedTopic] = useState(null);
    const [previewClips, setPreviewClips] = useState([]);
    const [previewClipIndex, setPreviewClipIndex] = useState(0);
    const { height, width } = useWindowDimensions();

    const previewClipsScrollContainerId = 'previewClipsScrollContainer';

    useEffect(() => {
        if (authenticated && !forMeData) {
            loadForMe();
        }
    }, [authenticated]);

    useEffect(() => {
        if (previewClipIndex < previewClips.length) {
            let clipId = previewClips[previewClipIndex].id
            eventClipView(clipId, 0);
        }
    }, [previewClipIndex, previewClips])

    useEffect(() => {
        if (!forMeData) {
            setPreviewClips([]);
        } else {
            setPreviewClips(forMeData.clips);
        }
    }, [forMeData])

    const navigate = useNavigate();

    const selectTopic = (topic) => () => {
        if (topic) {
            if (selectedTopic !== topic.id) {
                eventLog(7, {
                    'topicId': topic.id,
                });
                setSelectedTopic(topic.id);
                const container = document.getElementById(previewClipsScrollContainerId);
                container.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "instant",
                });
                setPreviewClipIndex(0);
                setPreviewClips(topic.clips);
            }
        } else {
            if (selectedTopic !== null) {
                setSelectedTopic(null);
                const container = document.getElementById(previewClipsScrollContainerId);
                container.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "instant",
                });
                setPreviewClipIndex(0);
                setPreviewClips(forMeData.clips);
            }
        }

    }

    const playClip = (clipId) => () => {
        let clipIndex = 0;
        for (let i = 0; i < previewClips.length; i++) {
            if (clipId === previewClips[i].id) {
                clipIndex = i;
                break;
            }
        }
        const clips = previewClips.slice(clipIndex, previewClips.length).concat(previewClips.slice(0, clipIndex))
        loadTrack(clips[0].mp3URL, 0)
        setNextClipsInfo(clips);
        navigate('/clips', { state: { closeAllModal: true } });
    }

    const scrollToClipId = (clipId) => () => {
        const elem = document.getElementById(clipId);
        if (elem) {
            elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
        }
    }

    const prevClip = () => {
        const newIndex = Math.max(0, previewClipIndex - 1);
        const elem = document.getElementById(previewClips[newIndex].id);
        if (elem) {
            elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
        }
    }

    const nextClip = () => {
        const newIndex = Math.min(previewClips.length - 1, previewClipIndex + 1);
        const elem = document.getElementById(previewClips[newIndex].id);
        if (elem) {
            elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
        }
    }

    const settingsClick = () => {
        navigate('/settings');
    }

    const getStartedClick = () => {
        navigate('/get_started');
    }


    return (
        <div>
            {width < RESPONSIVE_LARGE_WIDTH ? <></> :
                <div style={{ marginBottom: '5px', marginLeft: '20px', marginRight: '20px' }}>
                    <button onClick={settingsClick} className='settingsButton'>
                        <TuneRounded style={{ fontSize: '24px', verticalAlign: 'middle' }} />
                    </button>
                    <button onClick={selectTopic(null)} className={selectedTopic === null ? 'selectedTopic' : 'unselectedTopic'} style={{ margin: '0px 8px 8px 0px' }}>
                        for me
                    </button>
                    {forMeData.topics.map((topic, topicIndex) => (
                        <button key={topic.id} onClick={selectTopic(topic)} className={selectedTopic === topic.id ? 'selectedTopic' : 'unselectedTopic'} style={{ margin: '0px 8px 8px 0px' }}>
                            {topic.id}
                        </button>
                    ))}
                </div>
            }

            {forMeLoadingCount > 0 ?
                <div>
                    <ClipLargePreviewLoading />
                </div>
                :
                previewClips.length < 1 ? <></> :
                    <div>
                        <div id={previewClipsScrollContainerId} style={{ scrollPadding: '20px' }} className='hslider-container hideScrollbar horizontalScroll'>
                            {getStartedTopicsStatus === false ?
                                <span className='hslider-children' style={{ lineHeight: 0, paddingLeft: '20px', paddingRight: '10px' }}>
                                    <button onClick={getStartedClick}>
                                        <GetStartedCard />
                                    </button>
                                </span>
                                :
                                <span className='hslider-children' style={{ paddingLeft: '20px' }}></span>
                            }
                            {previewClips.map((clip, clipIndex) => (
                                <span key={clipIndex} className='hslider-children' style={{ paddingRight: clipIndex === previewClips.length - 1 ? '20px' : '10px' }}>
                                    <button onClick={clipIndex === previewClipIndex ? playClip(clip.id) : scrollToClipId(clip.id)} style={{ textAlign: 'left' }}>
                                        <ClipLargePreview clip={clip} index={clipIndex} clipIndexInViewport={(x) => { setPreviewClipIndex(x) }} />
                                    </button>
                                </span>
                            ))}
                        </div>

                    </div>
            }

            {width >= RESPONSIVE_LARGE_WIDTH ?
                <table style={{ marginTop: '16px', padding: '0px 20px', borderSpacing: 0, width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ paddingRight: '5px' }}>
                                <button onClick={prevClip} className={'nextPrevButtons'} style={{ opacity: previewClipIndex === 0 ? 0.5 : 1 }}>
                                    <NavigateBefore style={{ color: 'rgb(30, 30, 30)', fontSize: '24px', verticalAlign: 'middle' }} />
                                </button>
                            </td>
                            <td style={{ paddingLeft: '5px' }}>
                                <button onClick={nextClip} className={'nextPrevButtons'} style={{ opacity: previewClipIndex + 1 === previewClips.length ? 0.5 : 1 }}>
                                    <NavigateNext style={{ color: 'rgb(30, 30, 30)', fontSize: '24px', verticalAlign: 'middle' }} />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                :
                <div style={{ marginTop: '16px' }} className='hideScrollbar horizontalScroll'>
                    <span style={{ marginLeft: '20px' }}>
                        <button onClick={settingsClick} className='settingsButton'>
                            <TuneRounded style={{ fontSize: '24px', verticalAlign: 'middle' }} />
                        </button>
                    </span>


                    {forMeLoadingCount > 0 ? <></> :
                        <span>
                            <button onClick={selectTopic(null)} className={selectedTopic === null ? 'selectedTopic' : 'unselectedTopic'} style={{ marginRight: '8px' }}>
                                for me
                            </button>
                            {forMeData.topics.map((topic, topicIndex) => (
                                <button key={topic.id} onClick={selectTopic(topic)} className={selectedTopic === topic.id ? 'selectedTopic' : 'unselectedTopic'} style={{ marginRight: topicIndex === forMeData.topics.length - 1 ? '20px' : '8px' }}>
                                    {topic.id}
                                </button>
                            ))}
                        </span>
                    }

                </div>
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
    forMeData: state.user.forMeData,
    forMeLoadingCount: state.user.forMeLoadingCount,
    getStartedTopicsStatus: state.user.getStartedTopicsStatus,
})

const mapActionsToProps = {
    loadForMe,
    setNextClipsInfo,
}

export default connect(mapStateToProps, mapActionsToProps)(ForMePreview);