import PropTypes from 'prop-types';
import axios from 'axios';

import { useEffect, useRef, useState } from "react";

// Redux.
import { connect } from 'react-redux';

// Firebase.
import { getAnalytics, logEvent } from "firebase/analytics";

// Components.
import useIsInViewport from "./useIsInViewport";
import useWindowDimensions from "./windowHelper";
import ProfilePicture from './ProfilePicture';
import { getColorForNum } from '../constants/colors';
import { fancyTimeFormatWithHMS, getDateString } from './timeHelper';
import { PREVIEW_CARD_HEIGHT, PREVIEW_NEXT_CLIP_BUFFER } from '../constants/sizes';


const ClipLargePreview = ({
    clip,
    index,
    clipIndexInViewport,

    // Redux state.

    // Redux actions.
}) => {

    const currentViewport = useRef();

    const analytics = getAnalytics();
    const isCurrentViewport = useIsInViewport(currentViewport);

    const { _, width } = useWindowDimensions();
    const bottomDetailsRef = useRef();

    useEffect(() => {
        if (!isCurrentViewport) {

        } else {
            clipIndexInViewport(index);
        }
    }, [isCurrentViewport]);

    const padding = 20;

    return (
        <div id={clip.id}
            style={{
                // display: 'inline-block',
                opacity: 1.0,
                width: `${Math.min(width, 540) - PREVIEW_NEXT_CLIP_BUFFER}px`,
                background: `${getColorForNum(index)}35`,
                padding: `${padding}px`,
                borderRadius: '8px',
                height: `${PREVIEW_CARD_HEIGHT}px`,
            }}>

            <div style={{ position: 'sticky', top: 0, marginTop: '-1px', maxHeight: '1px', textAlign: 'center' }}>
                <span style={{ display: 'inline-block', minWidth: '1px' }} ref={currentViewport}></span>
            </div>
            <div>
                <div style={{ paddingTop: `${bottomDetailsRef.current ? (PREVIEW_CARD_HEIGHT - (2 * padding) - bottomDetailsRef.current.clientHeight - 120 - 20) / 2 : 0}px` }}>
                    <div className="profileAndSourceImageContainer">
                        <div className="profilePicWithSourceImage">
                            <ProfilePicture
                                imageSrc={clip.person.imageURL}
                                color={getColorForNum(index)}
                                name={clip.person.name}
                                profileClick={null}
                                size='120px'
                            />
                        </div>
                        <div>
                            <img src={clip.source.imageURL} alt="source" className="sourceImageOverlay" />
                        </div>
                    </div>
                </div>


                <div ref={bottomDetailsRef} style={{ position: 'absolute', bottom: '20px', width: `${Math.min(width, 540) - PREVIEW_NEXT_CLIP_BUFFER - (2 * padding)}px` }}>
                    <div className='clipLargePreviewTitle' >
                        {clip.title}
                    </div>
                    <div style={{ margin: '10px 0px' }}>
                        <div className='speakerNameSmall'>
                            {clip.person.name}
                        </div>
                        <div className='speakerDescription'>
                            {clip.person.description}
                        </div>
                    </div>

                    <div className='speakerDescription'>
                        <span>
                            {fancyTimeFormatWithHMS(clip.totalTimeSeconds)}
                        </span>
                        <span style={{ padding: '0px 10px' }}>
                            ·
                        </span>
                        <span>
                            {getDateString(clip.publishedDate)}
                        </span>
                    </div>
                </div>

            </div>
        </div >
    );
};

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {

}

ClipLargePreview.propTypes = {

}

export default connect(mapStateToProps, mapActionsToProps)(ClipLargePreview);