import { useState } from "react";

const GetStartedEmojis = ({
    topicSelections,
}) => {
    const examples = [
        ["😂", "humor"],
        ["😰", "anxiety"],
        ["🫣", "shyness"],
        
        ["💪", "fitness"],
        ["😔", "grief"],
        ["🧘", "meditation"],
        
        ["🤝", "relationships"],
        ["😍", "dating"],
        ["👶", "parenting"],
        
        ["📚", "books"],
        ["🍿", "movies"],
        ["📺", "television"],
        
        ["🏀", "basketball"],
        ["🏈", "football"],
        ["🎾", "tennis"],
        
        ["🤼", "mma"],
        ["🥊", "boxing"],
        ["⛳", "golf"],
        
        ["🧗", "climbing"],
        ["🏃", "running"],
        ["🚴", "cycling"],
        
        ["🏄", "surfing"],
        ["⛷️", "skiing"],
        ["🏂", "snowboarding"],
        
        ["♠️", "poker"],
        ["♟️", "chess"],
        ["🎮", "video games"],
        
        ["🎲", "statistics"],
        ["🌌", "astronomy"],
        ["🏎️", "racing"],
        
        ["🩻", "health"],
        ["🧪", "science"],
        ["🧠", "neuroscience"],
        
        ["⚖️", "politics"],
        ["🤔", "philosophy"],
        ["🤖", "machine learning"],
        
        ["💰", "economy"],
        ["📈", "investing"],
        ["🦄", "startups"],
        
        ["✈️", "travel"],
        ["⚜️", "design"],
        ["🕶️", "fashion"],
        
        ["📜", "history"],
        ["🎨", "art"],
        ["🏛️", "architecture"],
        
        ["🎵", "music"],
        ["🎹", "piano"],
        ["🎸", "guitar"],

        ["☕️", "coffee"],
        ["🍳", "cooking"],
        ["🍷", "wine"],
    ]

    const [selectedIndices, setSelectedIndices] = useState(new Set());

    const indexClick = (index) => () => {
        if (selectedIndices.has(index)) {
            selectedIndices.delete(index);
        } else {
            selectedIndices.add(index);
        }
        setSelectedIndices(new Set(selectedIndices));
    }

    const savePicks = () => {
        const selectedTopics = [];
        const unselectedTopics = [];
        for (let i = 0; i < examples.length; i++) {
            const topic = examples[i][1];
            if (selectedIndices.has(i)) {
                selectedTopics.push(topic);
            } else {
                unselectedTopics.push(topic);
            }
        }
        topicSelections(selectedTopics, unselectedTopics);
    }

    return (
        <div>
            <div className='getStartedContainer'>
                {examples.map((val, i) => (
                    <button key={i} onClick={indexClick(i)} className={selectedIndices.has(i) ? 'getStartedTopicSelected' : 'getStartedTopic'}>
                        <div>
                            <div style={{ fontSize: '40px' }}>
                                {val[0]}
                            </div>
                            <div style={{ padding: '4px', fontSize: '14px' }}>
                                {val[1]}
                            </div>
                        </div>
                    </button>
                ))}
            </div>
            <div className='footer'>
                <div className='horizontalCenter'>
                    <div style={{ padding: '8px 20px', textAlign: 'center' }}>
                        {selectedIndices.size === 0 ?
                            <div style={{ height: '46px', paddingTop: '13px', fontSize: '16px' }}>
                                Pick 3 or more for the best results
                            </div>
                            :
                            <button onClick={savePicks} style={{ fontSize: '16px', textAlign: 'center' }}>
                                <div style={{ background: 'white', border: '1px solid rgb(224, 224, 224)', borderRadius: '20px', padding: '10px 60px', fontWeight: '500' }}>
                                    <span style={{ verticalAlign: 'middle' }}>
                                        Finish
                                    </span>
                                </div>
                            </button>
                        }
                    </div>

                </div>
            </div>
        </div>
    );
};

export default GetStartedEmojis;
