import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAnalytics, logEvent } from "firebase/analytics";

// Relative time.
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { LABEL_APPNAME } from '../constants/labels';

// Redux.
import { connect } from 'react-redux';

// Components.
import HomeHeader from './HomeHeader';
import NextUpEpisodes from './NextUpEpisodes';
import ForMePreview from './ForMePreview';

import TestFlight from './TestFlight';
import SearchBoxWithSuggestions from './SearchBoxWithSuggestions';
import PodcastAlbumSlider from './PodcastAlbumSlider';


const HomeContent = ({
    // Redux.
    authenticated,
}) => {
    const analytics = getAnalytics();

    const navigate = useNavigate();

    useEffect(() => {
        dayjs.extend(relativeTime);
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'home'
        });
    }, []);

    const navigateToUrl = (url) => () => {
        navigate(url);
    }

    const querySelected = (query) => {
        window.location.href = `/search/${encodeURIComponent(query)}`;
    }

    const loginClick = () => {
        logEvent(analytics, 'login_click');
        navigate('/login');
    }

    return (
        <div id="homeContent" className='container hideScrollbar'>
            <div>
                {!authenticated ?
                    <div>
                        <div className='horizontalCenter' style={{ textAlign: 'center' }}>
                            {/* <div style={{ margin: '20px', textAlign: 'right' }}>
                                <button onClick={searchClick} style={{ textAlign: 'right' }}>
                                    <SvgIcon sx={{ stroke: "#ffffff", strokeWidth: 0.6 }} >
                                        <Search style={{ fontSize: '24px', verticalAlign: 'middle' }} />
                                    </SvgIcon>
                                </button>
                            </div> */}

                            <div style={{ padding: '30px 0px 30px' }}>
                                <div style={{ padding: '0px 0px 30px', overflow: 'hidden' }}>
                                    <PodcastAlbumSlider />
                                </div>
                                <div style={{ lineHeight: '1.0' }}>
                                    <img className='appName' src='/appname2.png' />
                                </div>
                                <div style={{ textAlign: 'center', padding: '30px 0px 15px' }}>
                                    <TestFlight />
                                </div>
                                <div>
                                    <button className='homeButtonLight' onClick={loginClick}>
                                        <span style={{ verticalAlign: 'bottom' }}>
                                            Log in or sign up
                                        </span>
                                        {/* <ChevronRightRounded style={{ fontSize: '22px', verticalAlign: 'bottom' }} /> */}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div style={{ padding: '30px 0px', borderTop: '10px solid rgb(250, 250, 250)' }}>
                            <div className='horizontalCenter' style={{ textAlign: 'center' }}>
                                <div style={{ margin: '0px auto', maxWidth: '340px' }}>
                                    <div className='homePreview'>
                                        <div style={{ fontWeight: '600', lineHeight: 1.3 }}>
                                            Discover New Podcasts
                                        </div>
                                        <div>
                                            <div style={{ paddingTop: '30px' }}>
                                                Struggling to choose from over 3 million podcasts?
                                            </div>
                                            <div style={{ paddingTop: '30px' }}>
                                                Podeux makes it easy with podcast previews just for you.
                                            </div>
                                            <div style={{ paddingTop: '30px' }}>
                                                Discover your next favorite podcast with Podeux.
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>

                        <div style={{ padding: '40px 0px 40px', borderTop: '10px solid rgb(250, 250, 250)' }}>
                            <div className='horizontalCenter' style={{ textAlign: 'center' }}>
                                <div style={{ textAlign: 'center', padding: '0px 0px 20px' }}>
                                    <TestFlight />
                                </div>
                                <div>
                                    <button className='homeButtonLight' onClick={loginClick}>
                                        <span style={{ verticalAlign: 'bottom' }}>
                                            Log in or sign up
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div style={{ background: 'rgb(250, 250, 250)', padding: '16px', textAlign: 'center', fontSize: '13px', color: 'rgb(113, 113, 113)'}}>
                            <span style={{  padding: '0px 13px' }}>
                                <a style={{ color: 'rgb(113, 113, 113)' }} href='/terms.html'>Terms of Use</a>
                            </span>
                            <span style={{  padding: '0px 13px' }}>
                                <a style={{ color: 'rgb(113, 113, 113)' }} href='/privacy.html'>Privacy Policy</a>
                            </span>
                            <span style={{  padding: '0px 13px' }}>
                                <a style={{ color: 'rgb(113, 113, 113)' }} href='/support'>© 2024 {LABEL_APPNAME}</a>
                            </span>
                        </div>
                    </div>
                    :
                    <div>
                        <HomeHeader />
                        <div style={{ padding: '10px 20px 10px' }}>
                            <SearchBoxWithSuggestions querySelected={querySelected} initialQuery={''} />
                        </div>
                        {NoQueryView(navigateToUrl)}
                    </div>
                }
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(HomeContent);


const NoQueryView = (navigateToUrl) => {
    const forMeClick = () => {
        // TODO use navigate?
        window.location = '/clips';
    }

    return (
        <div style={{ fontSize: '16px' }}>
            <div>
                <div>
                    <div style={{ padding: '16px 0px' }}>
                        <div style={{ padding: '0px 20px 13px' }}>
                            <button onClick={forMeClick} className='heading'>
                                <span style={{ verticalAlign: 'middle' }}>
                                    Previews
                                </span>
                            </button>

                        </div>

                        <ForMePreview />
                    </div>

                    <NextUpEpisodes navigateToUrl={navigateToUrl} />
                </div>
            </div>
        </div>
    );
}

