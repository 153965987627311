import { useEffect } from 'react'


// Redux.
import { connect } from 'react-redux';

// Firebase.
import { getAnalytics, logEvent } from "firebase/analytics";
import 'firebase/compat/auth';
import { LABEL_TAGLINE } from '../constants/labels';

// Components.


const Support = ({
}) => {

    useEffect(() => {
        const analytics = getAnalytics();
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'support'
        });
    }, []);

    return (
        <div className="container">
            <div className='center'>
                <div style={{ textAlign: 'left', paddingBottom: '40px' }}>
                    <div style={{ paddingBottom: '20px', marginBottom: '20px', borderBottom: '1px solid rgb(242, 242, 242)' }}>
                        <a href='/'>
                            <div>
                                <img className='appName' src='/appname2.png' />
                            </div>
                            <div className='tagline' style={{ fontSize: '20px' }}>
                                {LABEL_TAGLINE}
                            </div>
                        </a>
                    </div>

                    <div style={{ minWidth: '300px', fontSize: '18px', paddingBottom: '10px' }}>
                        <a href="mailto:john@podeux.com">john@podeux.com</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

Support.propTypes = {

}

Support.defaultProps = {

}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(Support);