const SpeedActions = ({
    onSpeedClick,
}) => {
    const speedValues = [0.75, 1, 1.25, 1.5, 1.75, 2];
    const audio = document.getElementById('audio');

    return <table style={{ borderSpacing: 0, width: '100%', textAlign: 'center', paddingBottom: '13px' }}>
        <tbody>
            <tr>
                {speedValues.map((val, i) => (
                    <td key={val} style={{ width: `${100.0 / speedValues.length}%` }}>
                        <button onClick={onSpeedClick(val)} style={{ width: '100%', padding: '10px 0px 13px', fontSize: '14px', fontWeight: '600', borderBottom: audio.playbackRate === val ? '3px solid black' : '1px solid rgb(224, 224, 224)' }}>
                            {val}
                        </button>
                    </td>)
                )}
            </tr>
        </tbody>
    </table>;
};

export default SpeedActions;

