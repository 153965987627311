
import { LinkRounded, PlayCircleFilled } from "@material-ui/icons";
import { getAnalytics, logEvent } from "firebase/analytics";
import BookmarkButton from "./BookmarkButton";
import TrackOverviewSpeaker from "./TrackOverviewSpeaker";
import { fancyTimeFormat, fancyTimeFormatWithHM } from './timeHelper';
import { forwardRef, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IosShareRounded } from "@mui/icons-material";
import { LABEL_APPNAME, LABEL_DOMAIN } from "../constants/labels";
import { isIPhone } from "./deviceHelper";
import { Modal } from "@mui/material";
import ButtonTipPopup from "./ButtonTipPopup";


const ButtonTipPopupRef = forwardRef((props, ref) => (
    <ButtonTipPopup {...props} />
));


export default function TrackInfoRow(props) {
    const { trackInfo, playTrack } = props;
    const analytics = getAnalytics();
    const navigate = useNavigate();

    const goToPodcast = (podcastId) => () => {
        navigate("/podcast/" + podcastId);
    }

    const [showCopied, setShowCopied] = useState(false);
    const toggleShowCopied = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setShowCopied(open);
    }

    const shareTrack = () => {
        const link = `https://${LABEL_DOMAIN}/track/${encodeURIComponent(trackInfo.id)}`;
        logEvent(analytics, 'share_track');
        if (navigator.share && isIPhone()) {
            const shareText = trackInfo.source.title + '\n\n' + link;
            navigator.share({
                title: LABEL_APPNAME,
                text: shareText,
            })
                .then(() => { })
                .catch((error) => {
                    console.log('Error sharing', error)
                });
        } else {
            navigator.clipboard.writeText(link);
            setShowCopied(true);
        }
    }

    return (
        <div>
            <Modal
                BackdropProps={{ style: { backgroundColor: 'rgb(0, 0, 0, 0)' } }}
                open={showCopied}
            >
                <ButtonTipPopupRef close={toggleShowCopied(false)} content={(
                    <div>
                        <div>
                            <LinkRounded style={{ fontSize: '80px' }} />
                        </div>
                        <div style={{ marginTop: '20px', fontSize: '24px', fontWeight: '600' }}>
                            Copied to clipboard
                        </div>
                    </div>
                )} />
            </Modal>

            <div>
                <table style={{ width: '100%', textAlign: 'left', padding: '20px' }}>
                    <tbody>
                        <tr>
                            <td style={{ minWidth: '76px' }}>
                                <button onClick={goToPodcast(trackInfo.source.id)}>
                                    <img src={trackInfo.source.imageURL} className='trackInfoSourceImage' />
                                </button>
                            </td>
                            <td style={{ width: '100%' }}>
                                <button onClick={playTrack(trackInfo, trackInfo.lastListenSeconds || 0)} style={{ textAlign: 'left', width: '100%' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div className='trackInfoSourceTitle'>
                                            {trackInfo.source.title}
                                        </div>
                                    </div>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style={{ paddingLeft: '20px', paddingBottom: '20px' }}>
                <div style={{ padding: '0px 20px 0px 16px', borderLeft: '1px solid rgb(224, 224, 224)' }}>
                    {
                        trackInfo.speakers.map((speaker, speakerIndex) => (
                            <div key={speaker.id} style={{ paddingTop: speakerIndex === 0 ? 0 : '16px' }}>
                                <TrackOverviewSpeaker
                                    key={speakerIndex}
                                    speaker={speaker}
                                    speakerColor={'rgb(210, 210, 210)'}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>

            <table style={{ cellSpacing: 0, padding: '0px 20px 20px' }}>
                <tbody>
                    <tr>
                        <td style={{ width: '100%' }}>
                            <button onClick={playTrack(trackInfo, trackInfo.lastListenSeconds || 0)} className=''>
                                <PlayCircleFilled style={{
                                    verticalAlign: 'middle',
                                    fontSize: '30px',
                                    marginTop: '0px',
                                }} />
                                <span style={{ fontSize: '14px', verticalAlign: 'middle', paddingLeft: '8px' }}>
                                    {trackInfo.lastListenSeconds ?
                                        trackInfo.totalTimeSeconds - trackInfo.lastListenSeconds < 120 ?
                                            <span style={{ fontWeight: '600' }}>
                                                FINISHED
                                            </span> :
                                            `-${fancyTimeFormat(trackInfo.totalTimeSeconds - trackInfo.lastListenSeconds)}`
                                        :
                                        `${fancyTimeFormatWithHM(trackInfo.totalTimeSeconds)}`
                                    }
                                </span>
                            </button>
                        </td>
                        <td>
                            <button onClick={shareTrack}>
                                <IosShareRounded style={{ marginRight: '24px', verticalAlign: 'middle', fontSize: '22px' }} />
                            </button>
                        </td>

                        <td>
                            <BookmarkButton trackId={trackInfo.id}
                                buttonClassName=''
                                iconStyle={{
                                    verticalAlign: 'middle',
                                    fontSize: '25px',
                                }} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
