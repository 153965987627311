import { useRef, useState } from 'react'

import { fancyTimeFormatWithHM, getDateString } from './timeHelper';
import BookmarkButton from './BookmarkButton';
import AudioPlayerDrawer from './AudioPlayerDrawer';
import { loadTrack } from './audioHelper';

const PodcastTrack = ({ trackInfo, authenticated }) => {

    const [showAudioPlayerDrawer, setShowAudioPlayerDrawer] = useState(false);

    const playTrack = (startSeconds) => () => {
        loadTrack(trackInfo.mp3URL, startSeconds);
        setShowAudioPlayerDrawer(true);
    };

    const closeAudioPlayer = () => {
        setShowAudioPlayerDrawer(false);
        document.getElementById('audio').pause();
    };

    return (
        <table style={{ paddingTop: '10px', paddingRight: '20px' }}>
            <AudioPlayerDrawer
                open={showAudioPlayerDrawer}
                onClose={closeAudioPlayer}
                source={trackInfo.source}
                trackId={trackInfo.id} />
            <tbody>
                <tr>
                    <td style={{ width: '100%' }}>
                        <button style={{ width: '100%', textAlign: 'left' }} onClick={playTrack(0)}>
                            <div className='speakerTrackTitle'>
                                {trackInfo.source.title}
                            </div>

                            <div style={{ fontWeight: '400', marginTop: '2px', fontSize: '13px', color: 'rgb(120, 120, 120)' }}>
                                {fancyTimeFormatWithHM(trackInfo.totalTimeSeconds)}
                                <span style={{ padding: '0px 8px' }}>·</span>
                                {getDateString(trackInfo.publishedDate)}
                            </div>
                        </button>
                    </td>
                    {authenticated ?
                        <td style={{ minWidth: '48px', textAlign: 'right' }}>
                            <BookmarkButton trackId={trackInfo.id}
                                buttonClassName='clipCardBookmarkButton'
                                iconStyle={{ verticalAlign: 'middle', color: '#202124', fontSize: '25px' }} />
                        </td>
                        :
                        <></>}
                </tr>
            </tbody>
        </table>
    );
};

export default PodcastTrack;