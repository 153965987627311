// React state and router.
import { useEffect, useRef, useState } from 'react'

// Redux.
import { connect } from 'react-redux';
import {
    setNextClipsInfo,
} from '../redux/actions/dataActions';

import {
    loadForMe,
} from '../redux/actions/userActions';

// Firebase.
import { getAnalytics, logEvent } from "firebase/analytics";


// Components.
import AudioPlayerDrawer from '../components/AudioPlayerDrawer';
import BookmarkButton from '../components/BookmarkButton';
import ClipCard from '../components/ClipCard';
import Loading from '../components/Loading';

// Component helpers.
import { loadTrack } from "../components/audioHelper"

// Constants.
import { useLocation, useNavigate } from 'react-router-dom';
import useWindowDimensions from '../components/windowHelper';
import { ChevronLeft, ChevronRight, HomeRounded, SkipPrevious } from '@material-ui/icons';
import { eventLog } from '../components/eventHelper';
import { AirlineStops, PlayCircleRounded, PlaylistRemove } from '@mui/icons-material';
import { ACCENT_COLOR } from '../constants/colors';
import { TIP_PLAY_FIRST_CLIP } from '../constants/local_storage_constants';
import { RESPONSIVE_LARGE_WIDTH } from '../constants/sizes';
import { Drawer } from '@mui/material';
import { getDateString } from '../components/timeHelper';
import { largeSourceImage } from '../components/trackHelper';

const _NUM_CLIPS_TO_PREFETCH = 1;

const Clips = ({
    // Redux state.
    authenticated,
    forMeData,
    forMeLoadingCount,
    nextClipsInfo,
    // TODO create new redux field clip that differs from track.
    // Currently both clips and track use "track" field.
    // A clip has additional fields such as "fullTrackId" and "relatedTopicClips"
    track,

    // Redux actions.
    loadForMe,
    setNextClipsInfo,
}) => {
    const analytics = getAnalytics();
    const audio = document.getElementById('audio');
    const navigate = useNavigate();

    const { height, width } = useWindowDimensions();

    const [currentClipIndex, setCurrentClipIndex] = useState(0);

    const [curTime, setCurTime] = useState(0);
    const [duration, setDuration] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [loading, setLoading] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(null);
    
    const finishedClipIds = useRef(new Set());

    const clipIndexInViewport = (clipIndex) => {
        setCurrentClipIndex(clipIndex);
        setTimeRemaining(NaN);
    }

    const [showAudioPlayerDrawer, setShowAudioPlayerDrawer] = useState(false);

    const closeAudioPlayer = () => {
        setShowAudioPlayerDrawer(false);
        // if (nextClipsInfo && nextClipsInfo.clipsInfo.length !== 0) {
        //     playClip(nextClipsInfo.clipsInfo[currentClipIndex]);
        // }
    }

    useEffect(() => {
        if (!authenticated && !nextClipsInfo) {
            navigate('/');
        }

        logEvent(analytics, 'screen_view', {
            firebase_screen: 'clips'
        });

        const setAudioTime = () => {
            setCurTime(audio.currentTime);
            const remaining = (audio.duration - audio.currentTime) / audio.playbackRate;
            setTimeRemaining(remaining);
            if (!playing && !audio.paused) {
                setPlaying(true);
            }
            if (!duration) {
                setDuration(audio.duration);
            }
        }

        const onPause = (event) => {
            setPlaying(false);
        }

        const onPlay = (event) => {
            setPlaying(true);
        }

        // DOM listeners: update React state on DOM events
        audio.addEventListener('timeupdate', setAudioTime);
        audio.addEventListener('pause', onPause);
        audio.addEventListener('play', onPlay);

        // effect cleanup
        return () => {
            audio.removeEventListener('timeupdate', setAudioTime);
            audio.removeEventListener('pause', onPause);
            audio.removeEventListener('play', onPlay);
        }
    }, []);

    useEffect(() => {
        if (authenticated && !nextClipsInfo) {
            if (forMeLoadingCount === 0) {
                loadForMe();
            }
        }
    }, [authenticated, nextClipsInfo])

    useEffect(() => {
        if (forMeData && !nextClipsInfo) {
            setNextClipsInfo(forMeData.clips);
        }
    }, [nextClipsInfo, forMeData])

    const { state } = useLocation();
    useEffect(() => {
        if (state && state.closeAllModal) {
            setLoading(true);
            clipsContainerScrollReset();
            setCurrentClipIndex(0);
            setTimeout(() => {
                setLoading(false);
                if (state) {
                    state.closeAllModal = false;
                }
            }, 500)
        }
    }, [nextClipsInfo])

    const clipsContainerScrollReset = () => {
        const clipsScrollContainer = document.getElementById('clipsScrollContainer');
        if (clipsScrollContainer) {
            clipsScrollContainer.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }
    }

    const playClip = (clip) => {
        setCurTime(0);
        setTimeRemaining(clip.totalTimeSeconds / audio.playbackRate);
        loadTrack(clip.mp3URL, 0, true, true);
    }

    const nextClip = () => {
        const nextIndex = currentClipIndex + 1;
        if (nextClipsInfo && nextIndex < nextClipsInfo.clipsInfo.length) {
            const nextElementId = nextClipsInfo.clipsInfo[nextIndex].id;
            const elem = document.getElementById(nextElementId);
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
            }
        }
    }

    const prevClip = () => {
        let prevIndex = currentClipIndex - 1;
        if (nextClipsInfo && prevIndex >= 0 && prevIndex < nextClipsInfo.clipsInfo.length) {
            const prevElementId = nextClipsInfo.clipsInfo[prevIndex].id;
            const elem = document.getElementById(prevElementId);
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: "start" });
            }
        }
    }

    const [showFullEpisodeContinueOrStart, setShowFullEpisodeContinueOrStart] = useState(false);
    const fullEpisodeClick = () => {
        if (!track) {
            return;
        }
        audio.pause();
        setShowFullEpisodeContinueOrStart(true);
        // TODO show drawer with continue playing or start from beginning.
        // startSeconds = track.clipStartTime + curTime
        // playFullEpisode(startSeconds)
    }

    const startFromBeginning = () => {
        playFullEpisode(0);
    }

    const continueListening = () => {
        playFullEpisode(track.clipStartTime + curTime)
    }

    const playFullEpisode = (startSeconds) => {
        setShowFullEpisodeContinueOrStart(false);
        const topicIds = [];
        for (let i = 0; i < track.relatedTopicClips.length; i++) {
            topicIds.push(track.relatedTopicClips[i].id);
        }

        eventLog(6, {
            'clipId': track.id,
            'speakerIds': track.titleSpeakerIds,
            'topicIds': topicIds,
        });
        logEvent(analytics, 'clip_card_continue_listening');
        loadTrack(track.trackMp3URL, startSeconds);
        setShowAudioPlayerDrawer(true);
    }

    useEffect(() => {
        if (track && currentClipIndex < nextClipsInfo.clipsInfo.length &&
            track.mp3URL === nextClipsInfo.clipsInfo[currentClipIndex].mp3URL) {
            if ((!isNaN(timeRemaining) && timeRemaining === 0)) {
                document.getElementById('clipfinished').play().catch(function () {
                    // Browser doesn't allow play until user click.
                });                
                nextClip();
                setTimeout(() => {
                    finishedClipIds.current.add(nextClipsInfo.clipsInfo[currentClipIndex].id);
                }, 700);
            }
        }
    }, [timeRemaining, track])

    const playCurrentClip = () => {
        localStorage.setItem(TIP_PLAY_FIRST_CLIP, true);
        audio.play().catch(function () {
            // Browser doesn't allow play until user click.
        });
    }

    const playFirstClipButton = (
        <button onClick={playCurrentClip} style={{
            padding: '8px',
            borderRadius: '8px',
            textAlign: 'left',
            width: '100%',
        }}>
            <table
                style={{ borderSpacing: 0, textAlign: 'left', width: '100%' }}>
                <tbody>
                    <tr>
                        <td style={{ paddingRight: '12px' }}>
                            <PlayCircleRounded style={{ verticalAlign: 'middle', fontSize: '42px', color: ACCENT_COLOR }} />
                        </td>
                        <td style={{ width: '100%' }}>
                            <div style={{ fontSize: '16px', fontWeight: '600' }}>
                                Play Preview
                            </div>
                            <div className='sourceTitle'>
                                Generated with AI
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </button>
    )

    const clipFullEpisodePreview = (
        <div style={{
            padding: '8px',
            borderRadius: '8px',
            background: '#FFFFFF81'
        }}>
            {!track || track.mp3URL !== audio.src ?
                <table
                    style={{ borderSpacing: 0, textAlign: 'left', width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ paddingRight: '12px' }}>
                                <div className='loading' style={{ height: '42px', width: '36px', borderRadius: '4px' }} />
                            </td>
                            <td style={{ width: '100%' }}>
                                <div className='loading' style={{ height: '42px', width: '100%', borderRadius: '4px' }} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                :
                <div>
                    <table style={{ width: '100%', borderSpacing: 0, textAlign: 'left' }}>
                        <tbody>
                            <tr>
                                <td style={{ paddingRight: '12px' }}>
                                    <button onClick={fullEpisodeClick}>
                                        <img alt='source' src={track.source.imageURL} className='sourceImage' />
                                    </button>
                                </td>
                                <td style={{ width: '100%' }}>
                                    <button style={{ textAlign: 'left', width: '100%' }} onClick={fullEpisodeClick}>
                                        <div style={{ textAlign: 'left' }}>
                                            <div style={{ fontSize: '14px', fontWeight: '500' }}>
                                                Full Episode
                                            </div>
                                            <div className='sourceTitle'>
                                                {track.source.title}
                                            </div>
                                        </div>
                                    </button>
                                </td>
                                {authenticated ?
                                    <td style={{ minWidth: '40px', textAlign: 'right' }}>
                                        <BookmarkButton trackId={track.fullTrackId ?? track.id}
                                            buttonClassName='clipCardBookmarkButton'
                                            iconStyle={{ color: '#202124', fontSize: '25px' }} />
                                    </td>
                                    :
                                    <></>
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )

    const homeClick = () => {
        audio.pause();
        navigate('/')
    }

    return (
        <div className="homeContainer menuBackground">

            <AudioPlayerDrawer
                open={showAudioPlayerDrawer}
                onClose={closeAudioPlayer}
                source={track ? track.source : null}
                trackId={track ? track.fullTrackId ?? track.id : null} />

            <Drawer
                PaperProps={{
                    sx: {
                        borderRadius: '10px 10px 0px 0px'
                    }
                }}
                anchor='bottom' open={showFullEpisodeContinueOrStart} onClose={() => { setShowFullEpisodeContinueOrStart(false) }}>
                {/* <div className='horizontalCenter' style={{ height: height - 30 }}>
                    <SearchTranscript onMessageTimeClick={seek} curTime={curTime} close={toggleSearchTranscript(false)} />
                </div> */}
                {!track ?
                    <div>
                        Unexpected Error.
                    </div>
                    :
                    <div style={{ padding: '30px 0px 30px' }}>
                        <div className='horizontalCenter'>
                            <div style={{ textAlign: 'center', padding: '0px 20px 30px' }}>
                                <div>
                                    <button onClick={() => { setShowFullEpisodeContinueOrStart(false) }}>
                                        <img src={largeSourceImage(track.source.imageURL)} style={{ borderRadius: '8px', width: '220px' }} />
                                    </button>

                                </div>
                                <div style={{ marginTop: '20px', fontSize: '16px', fontWeight: '500' }}>
                                    {track.source.title}
                                </div>
                                <div style={{ fontSize: '14px', paddingTop: '3px', color: 'rgb(120, 120, 120)' }}>
                                    <span>
                                        {getDateString(track.publishedDate)}
                                    </span>

                                </div>
                            </div>
                            <button onClick={startFromBeginning} style={{ width: '100%', padding: '6px 20px', }}>
                                <div style={{ textAlign: 'center', border: '1px solid rgb(224, 224, 224)', padding: '13px 20px', borderRadius: '8px' }}>
                                    <SkipPrevious style={{ fontSize: '24px', verticalAlign: 'middle', marginRight: '16px' }} />
                                    <span style={{ fontSize: '16px', fontWeight: '500', verticalAlign: 'middle' }}>
                                        Start From Beginning
                                    </span>
                                </div>
                            </button>
                            <button onClick={continueListening} style={{ width: '100%', padding: '6px 20px', }}>
                                <div style={{ textAlign: 'center', border: '1px solid rgb(224, 224, 224)', padding: '13px 20px', borderRadius: '8px' }}>
                                    <AirlineStops style={{ fontSize: '24px', verticalAlign: 'middle', marginRight: '16px' }} />
                                    <span style={{ fontSize: '16px', fontWeight: '500', verticalAlign: 'middle' }}>
                                        Continue Listening
                                    </span>
                                </div>
                            </button>
                        </div>
                    </div>
                }

            </Drawer >

            <div>
                {width < RESPONSIVE_LARGE_WIDTH ? <></> :
                    <div style={{ position: 'fixed', top: `${height / 2 - 56}px` }}>
                        <div style={{ display: 'inline-block', marginLeft: '-95px' }}>
                            <button disabled={currentClipIndex === 0} style={{ opacity: currentClipIndex === 0 ? 0.24 : 1 }} className='desktopPrevNextButton' onClick={prevClip}>
                                <ChevronLeft style={{ verticalAlign: 'middle', fontSize: '44px' }} />
                            </button>
                        </div>
                        <div style={{ display: 'inline-block', marginLeft: '600px' }}>
                            <button disabled={currentClipIndex === nextClipsInfo.clipsInfo.length - 1} style={{ opacity: currentClipIndex === nextClipsInfo.clipsInfo.length - 1 ? 0.24 : 1 }} className='desktopPrevNextButton' onClick={nextClip}>
                                <ChevronRight style={{ verticalAlign: 'middle', fontSize: '44px' }} />
                            </button>
                        </div>
                    </div>
                }

                <div className='main'>
                    <div id='clipsScrollContainer' className='hslider-container hideScrollbar horizontalScroll'>
                        {!nextClipsInfo || loading ?
                            <div><Loading /></div> :
                            <div>
                                {nextClipsInfo.clipsInfo.map((clip, index) => (
                                    <div key={clip.id} className='hslider-children' style={{ marginRight: index + 1 === nextClipsInfo.clipsInfo.length ? '30px' : '0px' }}>
                                        <ClipCard
                                            shouldLoadTrack={currentClipIndex + _NUM_CLIPS_TO_PREFETCH >= index}
                                            clip={clip}
                                            clipIndexInViewport={clipIndexInViewport}
                                            finishedClipIds={finishedClipIds}
                                            playFullEpisode={playFullEpisode}
                                            currentTime={curTime}
                                            index={index}
                                            playClip={playClip}
                                            nextClip={nextClip}
                                            playing={playing}
                                            showAudioPlayerDrawer={showAudioPlayerDrawer}
                                        />
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>

                <div className='footerNoBlur'>
                    <div className='horizontalCenter'>
                        <div style={{ padding: '8px' }}>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            <div className='bottomBar' style={{ width: '100%' }}>
                                                {!localStorage.TIP_PLAY_FIRST_CLIP && !playing ? playFirstClipButton : clipFullEpisodePreview}
                                            </div>
                                        </td>
                                        <td style={{ paddingLeft: '8px', paddingRight: '22px' }}>

                                            <button onClick={homeClick} className='bottomBar' style={{ height: '58px', width: '58px' }}>
                                                <HomeRounded style={{ verticalAlign: 'middle', fontSize: '25px' }} />
                                                {/* <img src='/icon.png' style={{ verticalAlign: 'middle', width: '24px' }} /> */}
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

const mapStateToProps = (state) => ({
    nextClipsInfo: state.data.nextClipsInfo,
    track: state.data.track,

    authenticated: state.user.authenticated,
    forMeData: state.user.forMeData,
    forMeLoadingCount: state.user.forMeLoadingCount,
})

const mapActionsToProps = {
    loadForMe,
    setNextClipsInfo,
}

export default connect(mapStateToProps, mapActionsToProps)(Clips);