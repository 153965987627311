import axios from 'axios';
import jwtDecode from 'jwt-decode';
import 'animate.css';
import './firebaseui-styling.global.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';

// Redux.
import { Provider } from 'react-redux';
import store from './redux/store';
import { loginUser, logoutUser } from './redux/actions/userActions';

// Routes.
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Clip from "./routes/clip";
import Clips from "./routes/clips"
import Down from "./routes/down"
import GetStarted from "./routes/get_started"
import Install from "./routes/install";
import Home from "./routes/home";
import Login from "./routes/login"
import Missing from "./routes/missing"
import Podcast from './routes/podcast';
import Recent from './routes/recent';
import Saved from './routes/saved';
import Search from "./routes/search";
import Speaker from "./routes/speaker";
import Subscriptions from "./routes/subscriptions";
import Support from "./routes/support";
import Topic from "./routes/topic";
import Track from "./routes/track";
import Settings from './routes/settings';
import MyTest from './routes/my_test';
import Menu from './routes/menu';


const token = localStorage.FirebaseIdToken;
if (token && token.startsWith('Bearer ') && localStorage.FirebaseRefreshToken) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    // Try to refresh token.
    delete axios.defaults.headers.common['Authorization'];
    axios.post('https://securetoken.googleapis.com/v1/token?key=AIzaSyBBuG-peMIN98DKoX4IR1c1uTM1nEIa7U4',
      'grant_type=refresh_token&refresh_token=' + localStorage.FirebaseRefreshToken,
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      }
    )
      .then((res) => {
        if (res.status === 200) {
          store.dispatch(loginUser(res.data.id_token, res.data.refresh_token, {}));
        } else {
          store.dispatch(logoutUser());
        }
      })
  } else {
    const tokenWithoutBearer = token.split('Bearer ')[1];
    store.dispatch(loginUser(tokenWithoutBearer, localStorage.FirebaseRefreshToken, {}));
  }
} else {
  store.dispatch(logoutUser());
}

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#5468ff'
      },
      secondary: {
        main: '#11cb5f',
      },
    },
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/" element={<Down />} /> */}
            <Route path="/clip">
              <Route path=":trackId">
                <Route path=":messageIndex" element={<Clip />}>
                </Route>
              </Route>
            </Route>
            <Route path="/app" element={<Install />}></Route>
            <Route path="/clips" element={<Clips />}></Route>
            <Route path="/get_started" element={<GetStarted />}></Route>
            <Route path="/home" element={<Home />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/menu" element={<Menu />}></Route>
            <Route path="/missing" element={<Missing />}></Route>
            <Route path="/podcast">
              <Route path=":podcastId" element={<Podcast />}>
              </Route>
            </Route>
            <Route path="/recent" element={<Recent />}></Route>
            <Route path="/saved" element={<Saved />}></Route>
            <Route path="/search" element={<Search />}>
              <Route path=":query" element={<Search />}>
              </Route>
            </Route>
            <Route path="/settings" element={<Settings />}></Route>
            <Route path="/speaker">
              <Route path=":speakerId" element={<Speaker />}>
              </Route>
            </Route>
            <Route path="/subscriptions" element={<Subscriptions />}></Route>
            <Route path="/support" element={<Support />}></Route>
            <Route path="/topic">
              <Route path=":topicId" element={<Topic />}>
              </Route>
            </Route>
            <Route path="/track">
              <Route path=":trackId" element={<Track />}>
              </Route>
            </Route>
            <Route path="/my_test" element={<MyTest />}></Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
