import axios from 'axios';

import { useEffect, useState } from 'react'

// Redux.
import { connect } from 'react-redux';
import Loading from './Loading'

import SpeakerHeader from './SpeakerHeader';
import SpeakerTrack from './SpeakerTrack';


const EntityInfo = (props) => {
    const {
        missingSpeaker,
        speakerId,

        // Redux
        authenticated,
    } = props;

    const [info, setInfo] = useState(null);

    useEffect(() => {
        axios
            .get(`/speaker/${encodeURIComponent(speakerId)}`)
            .then((res) => {
                setInfo(res.data);
            })
            .catch((err) => console.log(err));
    }, [speakerId]);

    return (
        <div>
            {!info ?
                <div>
                    <Loading overrideStyle={{ padding: '50px', textAlign: 'center' }} />
                </div>
                :
                !info.entity ?
                    !missingSpeaker ?
                        <div style={{ padding: '20px', fontSize: '24px', fontWeight: '600' }}>
                            Unknown speaker
                        </div> :
                        missingSpeaker
                    :
                    <div>
                        <SpeakerHeader summary={info.summary} speaker={info.entity} clips={info.clips} />

                        <div className='entityInfoContent'>
                            {
                                !info || !info.tracks || info.tracks.length === 0 ?
                                    <></>
                                    :
                                    <div>
                                        <div className='entityInfoSectionTitle'>
                                            Trending Episodes
                                        </div>
                                        {
                                            info.tracks.map((track, index) => (
                                                <div key={index} style={{ marginLeft: '20px', padding: '10px 0px', borderTop: index === 0 ? '' : '1px solid rgb(224, 224, 224)' }}>
                                                    <SpeakerTrack speakerId={info.entity.id} track={track} authenticated={authenticated} />
                                                </div>
                                            ))
                                        }
                                    </div>
                            }
                        </div>

                    </div>
            }
        </div >
    );
}

EntityInfo.propTypes = {

}

EntityInfo.defaultProps = {

}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(EntityInfo);
