import axios from 'axios';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react'

import { getAnalytics, logEvent } from "firebase/analytics";

import BottomHomeMenu from '../components/BottomHomeMenu';
import { LABEL_TAGLINE } from '../constants/labels';
import Loading from '../components/Loading';
import PodcastHeader from '../components/PodcastHeader';
import PodcastLatestEpisodes from '../components/PodcastLatestEpisodes';


const Podcast = ({

}) => {

    let params = useParams();
    const analytics = getAnalytics();

    const [podcast, setPodcast] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.getElementById('audio').pause();
        if (!!params.podcastId) {
            setLoading(true);
            axios
                .get(`/podcast/${params.podcastId}`)
                .then((res) => {
                    setLoading(false);
                    const data = res.data;
                    console.log(data);
                    setPodcast(data);
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                });
        } else {
            setLoading(false);
        }
    }, [params, setPodcast, setLoading]);

    useEffect(() => {
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'podcast'
        });
    }, [analytics]);

    const missingPodcast = (
        <div style={{ padding: '20px' }}>
            <div style={{ paddingBottom: '20px', marginBottom: '20px', borderBottom: '1px solid rgb(242, 242, 242)' }}>
                <a href='/'>
                    <div>
                        <img className='appName' src='/appname2.png' />
                    </div>
                    <div className='tagline'>
                        {LABEL_TAGLINE}
                    </div>
                </a>
            </div>

            <div style={{ textAlign: 'center' }}>
                <div style={{ padding: '40px 20px' }}>
                    <div style={{ fontSize: '18px', fontWeight: '500' }}>
                        Unknown Podcast
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div>
            <div className="container">
                <div className='animate__animated animate__fadeIn'>
                    <div style={{ background: 'white', paddingBottom: '80px' }}>
                        {loading ?
                            <Loading /> :
                            !!podcast ?
                                <div>
                                    <PodcastHeader podcast={podcast} />
                                    <PodcastLatestEpisodes podcastId={podcast.id} />
                                </div>

                                :
                                missingPodcast
                        }

                        <BottomHomeMenu />
                    </div>

                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(Podcast);