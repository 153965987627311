import axios from 'axios';

import { useEffect, useState } from 'react'
import PropTypes from 'prop-types';

// Redux.
import { connect } from 'react-redux';
import {
    forMeLoadingCountDecrease,
    forMeLoadingCountIncrease,
    updateForMe
} from '../redux/actions/userActions';

// Router.
import { Navigate, useNavigate } from 'react-router-dom';

// Firebase.
import { getAnalytics, logEvent } from "firebase/analytics";

// Components.

import { AddCircle, Close, Delete, Star } from '@material-ui/icons';
import SearchTopicsDrawer from '../components/SearchTopicsDrawer';

import { validTokenExists } from '../firebaseHelper';
import { ACCENT_COLOR } from '../constants/colors';
import { shuffle } from 'lodash';


const Settings = ({
    authenticated,
    forMeLoadingCountDecrease,
    forMeLoadingCountIncrease,
    updateForMe,
}) => {
    useEffect(() => {
        const analytics = getAnalytics();
        logEvent(analytics, 'screen_view', {
            firebase_screen: 'settings'
        });

        validTokenExists()
            .then(() => {
                setLoadingTopics(true);
                axios
                    .post('/user_topics', {})
                    .then((res) => {
                        const newTopicLevels = [];
                        const newSuggestedTopicLevels = [];
                        for (let i = 0; i < res.data.topicLevels.length; i++) {
                            const topicLevel = res.data.topicLevels[i];
                            if (topicLevel.level >= 1) {
                                newTopicLevels.push(topicLevel);
                            } else {
                                newSuggestedTopicLevels.push(topicLevel);
                            }
                        }
                        setTopicLevels(newTopicLevels);
                        setSuggestedTopicLevels(shuffle(newSuggestedTopicLevels).slice(0, 15));
                        setLoadingTopics(false);
                    })
                    .catch((err) => {
                        // TODO
                        console.log(err);
                        setLoadingTopics(false);
                    });
            })
            .catch((e) => {
                // Not logged in or network error?
            });
    }, []);

    const [showSearchTopic, setShowSearchTopic] = useState(false);

    const [topicLevels, setTopicLevels] = useState([]);
    const [suggestedTopicLevels, setSuggestedTopicLevels] = useState([]);

    const [loadingTopics, setLoadingTopics] = useState(false);

    const addTopicsClick = () => {
        setShowSearchTopic(true)
    }

    const _MAX_TOPIC_LEVEL = 3;

    const addTopicIdRequest = (topicId) => {
        validTokenExists()
            .then(() => {
                const reqBody = {
                    'addTopic': topicId,
                };
                forMeLoadingCountIncrease();
                axios
                    .post('/set_user_topic', reqBody)
                    .then((res) => {
                        updateForMe(res.data);
                        forMeLoadingCountDecrease();
                    })
                    .catch((err) => {
                        // TODO
                        forMeLoadingCountDecrease();
                        console.log(err);
                    });
            });
    }
    const addTopicId = (topicId) => {
        for (let i = 0; i < topicLevels.length; i++) {
            if (topicLevels.topicId === topicId) {
                // Topic already exists so return.
                return;
            }
        }

        const newSuggestedTopicLevels = suggestedTopicLevels.filter(function (topicLevel) {
            return topicId !== topicLevel.topicId;
        });
        setSuggestedTopicLevels(newSuggestedTopicLevels);
        const newTopicLevels = [{
            topicId: topicId,
            level: _MAX_TOPIC_LEVEL,
        }, ...topicLevels]
        setTopicLevels(newTopicLevels);
        addTopicIdRequest(topicId);
    }

    const starIconStyle = { fontSize: '11px', color: ACCENT_COLOR, verticalAlign: 'middle' }

    const TopicLevelButton = (topicLevel, onDelete, onClick) => (
        <table>
            <tbody>
                <tr>
                    <td style={{ minWidth: '50px' }}>
                        <button onClick={onClick}>
                            {topicLevel.level >= 1 ?
                                <span>
                                    {Array.from({ length: topicLevel.level }, (_, index) => (
                                        <Star key={index} style={starIconStyle} />
                                    ))}
                                </span>
                                :
                                <span>
                                    <AddCircle style={{ verticalAlign: 'middle', color: ACCENT_COLOR }} />
                                </span>
                            }
                        </button>
                    </td>
                    <td style={{  width: '100%' }}>
                        <button onClick={onClick}>
                            <span style={{ fontSize: '16px', fontWeight: '500', verticalAlign: 'middle' }} >{topicLevel.topicId}</span>
                        </button>
                    </td>
                    <td>
                        <button onClick={onDelete}>
                            <Delete style={{ fontSize: '21px', verticalAlign: 'middle', color: 'rgb(124, 124, 124)', marginLeft: '16px' }} />
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    );

    const navigate = useNavigate();

    const exit = () => {
        navigate('/home');
    }

    const onTopicDelete = (topicId) => () => {
        validTokenExists()
            .then(() => {
                const reqBody = {
                    'deleteTopic': topicId,
                };
                forMeLoadingCountIncrease();
                axios
                    .post('/set_user_topic', reqBody)
                    .then((res) => {
                        updateForMe(res.data);
                        forMeLoadingCountDecrease();
                    })
                    .catch((err) => {
                        // TODO
                        forMeLoadingCountDecrease();
                        console.log(err);
                    });
            });
        const newTopicLevels = topicLevels.filter(function (topicLevel) {
            return topicId !== topicLevel.topicId;
        });
        if (newTopicLevels.length !== topicLevels.length) {
            setTopicLevels(newTopicLevels);
            return
        }
        const newSuggestedTopicLevels = suggestedTopicLevels.filter(function (topicLevel) {
            return topicId !== topicLevel.topicId;
        });
        setSuggestedTopicLevels(newSuggestedTopicLevels);
    }

    const updateTopicLevel = (topicId, level) => {
        validTokenExists()
            .then(() => {
                const reqBody = {
                    'topicId': topicId,
                    'level': level
                };
                axios
                    .post('/set_topic_level', reqBody)
                    .then((res) => {

                    })
                    .catch((err) => {
                        // TODO
                        console.log(err);
                    });
            });
    };

    const onTopicClick = (topicId) => () => {
        let topicLevelUpdated = false;
        const newTopicLevels = [];
        for (let i = 0; i < topicLevels.length; i++) {
            const newTopicLevel = { ...topicLevels[i] };
            if (newTopicLevel.topicId === topicId) {
                newTopicLevel.level = (newTopicLevel.level % _MAX_TOPIC_LEVEL) + 1;
                topicLevelUpdated = true;
                updateTopicLevel(newTopicLevel.topicId, newTopicLevel.level);
            }
            newTopicLevels.push(newTopicLevel);
        }
        if (topicLevelUpdated) {
            setTopicLevels(newTopicLevels);
            return;
        }

        const newSuggestedTopicLevels = [];
        for (let i = 0; i < suggestedTopicLevels.length; i++) {
            const newTopicLevel = { ...suggestedTopicLevels[i] };
            if (newTopicLevel.topicId === topicId) {
                if (newTopicLevel.level === 0) {
                    newTopicLevel.level = _MAX_TOPIC_LEVEL;
                    addTopicIdRequest(topicId);
                } else {
                    newTopicLevel.level = (newTopicLevel.level % _MAX_TOPIC_LEVEL) + 1;
                }
                updateTopicLevel(newTopicLevel.topicId, newTopicLevel.level);
            }
            newSuggestedTopicLevels.push(newTopicLevel);
        }
        setSuggestedTopicLevels(newSuggestedTopicLevels);
    }


    if (!authenticated) {
        return <Navigate to={'/login'}></Navigate>
    } else {
        return (
            <div className="container bottomPadding">
                <SearchTopicsDrawer addTopicId={addTopicId} open={showSearchTopic} onClose={() => { setShowSearchTopic(false) }} />

                <div className='mainWithBottomMenu'>
                    <div>

                        <div className='heading' style={{ padding: '20px 20px 0px' }}>
                            Show me podcasts on...
                            <button onClick={exit} style={{ float: 'right', height: '40px', verticalAlign: 'middle' }}>
                                <Close style={{ color: 'rgb(124, 124, 124' }} />
                            </button>
                        </div>

                        {loadingTopics ?
                            <div className='glow' style={{ padding: '10px 20px' }}>
                                <button className='loadingSettingForMeTopic' />
                                <button className='loadingSettingForMeTopic' />
                                <button className='loadingSettingForMeTopic' />
                                <button className='loadingSettingForMeTopic' />
                                <button className='loadingSettingForMeTopic' />
                            </div>
                            :
                            <div>
                                {topicLevels.length === 0 ? <></> :
                                    <div style={{ padding: '20px' }}>
                                        <div style={{ background: 'rgb(245, 245, 245)', borderRadius: '10px', padding: '20px', textAlign: 'center' }}>
                                            <div style={{ fontSize: '14px', fontWeight: '500' }}>
                                                Tap topic to adjust interest level
                                            </div>
                                            <table style={{ margin: '10px auto 0px', width: '280px' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: '33%' }}>
                                                            <div>
                                                                <Star style={starIconStyle} />
                                                            </div>
                                                            <div style={{ fontSize: '14px', fontWeight: '500' }}>
                                                                Low
                                                            </div>
                                                        </td>
                                                        <td style={{ width: '33%' }}>
                                                            <div>
                                                                <Star style={starIconStyle} />
                                                                <Star style={starIconStyle} />
                                                            </div>
                                                            <div style={{ fontSize: '14px', fontWeight: '500' }}>
                                                                Med
                                                            </div>
                                                        </td>
                                                        <td style={{ width: '33%' }}>
                                                            <div>
                                                                <Star style={starIconStyle} />
                                                                <Star style={starIconStyle} />
                                                                <Star style={starIconStyle} />
                                                            </div>
                                                            <div style={{ fontSize: '14px', fontWeight: '500' }}>
                                                                High
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }

                                {topicLevels.map((topicLevel, i) => (
                                    <div key={i} className='settingForMeTopic'>
                                        {TopicLevelButton(topicLevel, onTopicDelete(topicLevel.topicId), onTopicClick(topicLevel.topicId))}
                                    </div>
                                ))}

                                {suggestedTopicLevels.length === 0 ? <></> :
                                    <div>
                                        <div style={{ fontSize: '16px', fontWeight: '500', padding: '40px 20px 20px' }}>
                                            Recommended based on activity
                                        </div>

                                        {suggestedTopicLevels.map((topicLevel, i) => (
                                            <div key={i} className='settingForMeTopic'>
                                                {TopicLevelButton(topicLevel, onTopicDelete(topicLevel.topicId), onTopicClick(topicLevel.topicId))}
                                            </div>
                                        ))}
                                    </div>
                                }

                                <div className='footer'>
                                    <div className='horizontalCenter'>
                                        <button onClick={addTopicsClick} style={{ padding: '10px 20px', width: '100%', fontSize: '16px', textAlign: 'center' }}>
                                            <div style={{ background: 'black', borderRadius: '20px', padding: '10px', fontWeight: '500' }}>
                                                <span style={{ color: 'white', verticalAlign: 'middle' }}>
                                                    Add Topics
                                                </span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

Settings.propTypes = {
    authenticated: PropTypes.bool.isRequired
}

Settings.defaultProps = {

}

const mapStateToProps = (state) => ({
    authenticated: state.user.authenticated,
})

const mapActionsToProps = {
    updateForMe,
    forMeLoadingCountDecrease,
    forMeLoadingCountIncrease,
}

export default connect(mapStateToProps, mapActionsToProps)(Settings);